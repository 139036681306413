/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.jsx";
import axios from 'axios';// reactstrap components
import swal from 'sweetalert'

class Profile extends React.Component {

  constructor() {
    super()
    this.changeHandler = this.changeHandler.bind(this)
  }

  state = {
    firstName: '',
    lastName: '',
    otherNames: '',
    email: '',
    phoneNumber: '',
    address: '',
    password: '',
    confirmPassword: '',

    dateOfBirth: '',
    nationality: '',
    stateOfOrigin: '',
    religion: '',
    registeredBy: '',
    schoolId: '',
    profilePicture: '',
    timeStampCreated: '2019-01-06T17:16:40',
    RoleId: '3FA85F64-5717-4562-B3FC-2C963F66AFA6',

    errors: []
  }

  componentDidMount() {
    const userId = sessionStorage.getItem("userId");
    axios.get(`/User/${userId}`)
      .then(response => {
        this.state.userId = response.data.userId
        this.state.firstName = response.data.firstName
        this.state.lastName = response.data.lastName
        this.state.otherNames = response.data.otherNames
        this.state.email = response.data.email
        this.state.phoneNumber = response.data.phoneNumber
        this.state.nationality = response.data.nationality
        this.state.dateOfBirth = response.data.dateOfBirth
        this.setState({ [this.state.firstName]: response.data.firstName })
        this.setState({ [this.state.lastName]: response.data.lastName })
        this.setState({ [this.state.otherNames]: response.data.otherNames })
        this.setState({ [this.state.email]: response.data.email })
        this.setState({ [this.state.phoneNumber]: response.data.phoneNumber })
        this.setState({ [this.state.nationality]: response.data.nationality })
        this.setState({ [this.state.userId]: response.data.userId })
        this.setState({ [this.state.dateOfBirth]: response.data.dateOfBirth })
      })
      .catch(error => {

      })
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    const { firstName, lastName, email, phoneNumber, address, otherNames, dateOfBirth, nationality } = this.state
    return (
      <>
        <UserHeader username={firstName} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={require("assets/img/theme/team-4-800x800.jpg")}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  <div className="d-flex justify-content-between">
                    <Button
                      className="mr-4"
                      color="info"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                      size="sm"
                    >
                      Connect
                    </Button>
                    <Button
                      className="float-right"
                      color="default"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                      size="sm"
                    >
                      Message
                    </Button>
                  </div>
                </CardHeader>
                <CardBody className="pt-0 pt-md-4">
                  {/*                   
                  <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                        <div>
                          <span className="heading">22</span>
                          <span className="description">Friends</span>
                        </div>
                        <div>
                          <span className="heading">10</span>
                          <span className="description">Photos</span>
                        </div>
                        <div>
                          <span className="heading">89</span>
                          <span className="description">Comments</span>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <div className="text-center">
                    <h3>
                      Jessica Jones
                      <span className="font-weight-light">, 27</span>
                    </h3>
                    <div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      Bucharest, Romania
                    </div>
                    <div className="h5 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      Solution Manager - Creative Tim Officer
                    </div>
                    <div>
                      <i className="ni education_hat mr-2" />
                      University of Computer Science
                    </div>
                    <hr className="my-4" />
                    <p>
                      Ryan — the name taken by Melbourne-raised, Brooklyn-based
                      Nick Murphy — writes, performs and records all of his own
                      music.
                    </p>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      Show more
                    </a>
                  </div>
                 */}
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">My account</h3>
                    </Col>
                    {/* <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        Settings
                      </Button>
                    </Col> */}
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      User information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              First name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="firstName"
                              name="firstName"
                              placeholder="first name"
                              type="text"
                              value={firstName}
                              onChange={this.changeHandler}
                              required='required'
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Last name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="lastName"
                              name="lastName"
                              placeholder="last name"
                              type="text"
                              value={lastName}
                              onChange={this.changeHandler}
                              required='required'
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-other-names"
                            >
                              Other names
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="otherNames"
                              name="otherNames"
                              placeholder=""
                              type="text"
                              value={otherNames}
                              onChange={this.changeHandler}
                              required='required'
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email-address"
                            >
                              Email address
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="email"
                              name="email"
                              placeholder="email"
                              type="email"
                              value={email}
                              onChange={this.changeHandler}
                              required='required'
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-phone-number"
                            >
                              Phone number
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="phoneNumber"
                              name="phoneNumber"
                              placeholder="tel"
                              type="text"
                              value={phoneNumber}
                              onChange={this.changeHandler}
                              required='required'
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-dob"
                            >
                              Date of birth
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="dateOfBirth"
                              name="dateOfBirth"
                              placeholder="dob"
                              type="text"
                              value={dateOfBirth}
                              onChange={this.changeHandler}
                              required='required'
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                      Contact information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Address
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="nationality"
                              name="nationality"
                              type="text"
                              value={nationality}
                              onChange={this.changeHandler}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              City
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue=""
                              id="input-city"
                              placeholder=""
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Country
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue=""
                              id="input-country"
                              placeholder=""
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Postal code
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-postal-code"
                              placeholder=""
                              type="number"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Description */}
                    {/* <h6 className="heading-small text-muted mb-4">About me</h6>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <label>About Me</label>
                        <Input
                          className="form-control-alternative"
                          placeholder="A few words about you ..."
                          rows="4"
                          defaultValue="A beautiful Dashboard for Bootstrap 4. It is Free and
                          Open Source."
                          type="textarea"
                        />
                      </FormGroup>
                    </div> */}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Profile;
