import React, { Component } from 'react'
// core components
import AdminHeader from "components/Headers/AdminHeader.jsx";
import axios from 'axios';
import Inputt, { PageHeader } from 'components/Reusable/PageHeader.jsx'
import swal from 'sweetalert'
// reactstrap components
import {
    Card,
    Container,
    Row,
    Button,
    CardBody,
    FormGroup,
    Form,
    Col
} from "reactstrap";

// let formInputs = []
let schoolList = []
let schoolToDisplay = []

class CreateUser extends Component {
    constructor() {
        super()
        this.changeHandler = this.changeHandler.bind(this)
        this.createUserHandler = this.createUserHandler.bind(this)
        this.populateSchool = this.populateSchool.bind(this)
    }

    state = {
        firstName: '',
        lastName: '',
        otherNames: '',
        email: '',
        phoneNumber: '',
        address: '',
        password: '',
        confirmPassword: '',

        dateOfBirth: '2019-01-06T17:16:40',
        nationality: '',
        stateOfOrigin: '',
        religion: '',
        registeredBy: '',
        schoolId: '',
        profilePicture: '',
        timeStampCreated: '2019-01-06T17:16:40',
        RoleId: '333b7e0f-785c-471d-87dc-08d7dbcd3754',
        
        schools: [],

        errors: []
    }

    componentDidMount() {
        this.populateSchool();
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    populateSchool() { 
        schoolToDisplay = []
        schoolToDisplay.push({ schoolId: '', Name: '--- SELECT ---' })
        axios.get("/School")
            .then(response => {
                if (response.data) {
                    schoolList = response.data
                    schoolList.map((school, index) => {
                        schoolToDisplay.push({ schoolId: school.schoolId, Name: school.name })
                    })
                    this.setState({ schools: schoolToDisplay })
                }
            })
            .catch(error => {

            })
    }

    createUserHandler = (e) => {
        e.preventDefault()
        // this.state.schoolId = sessionStorage.getItem('schoolId')
        this.state.registeredBy = sessionStorage.getItem('userId')
        // if (!this.handleValidation(this.formInputs)) {            
        //     return;
        // }
        axios.post("/user", this.state)
            .then(response => {
                if (response.data && response.data.status == 1) {
                    swal({
                        title: "Done!",
                        text: response.data.responseMessage,
                        icon: "success",
                        timer: 3000,
                        button: true
                    })
                }
                if (response.data && response.data.status == 0) {
                    swal({
                        title: "Failed!",
                        text: response.data.responseMessage,
                        icon: "info",
                        timer: 3000,
                        button: true
                    })
                }
            })
            .catch(error => {
                swal({
                    title: "Error!",
                    text: "unable to process your request",
                    icon: "error",
                    timer: 3000,
                    button: true
                })
            })
    }

    render() {
        const { firstName, lastName, email, phoneNumber, address, otherNames, password, 
            confirmPassword, schoolId } = this.state
       
        const selectSchoolItems = this.state.schools.map((school, index) => {
            return <option key={school.schoolId} value={school.schoolId}>
                {school.Name}
            </option>
        })
         return (
            <>
                <AdminHeader />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <PageHeader heading="User information" />
                                <CardBody>
                                    <Form onSubmit={this.createUserHandler}>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">First name</label>
                                                        <Inputt
                                                            className="form-control-alternative form-control"
                                                            id="firstName"
                                                            name="firstName"
                                                            type="text"
                                                            placeholder="first name"
                                                            value={firstName}
                                                            onChange={this.changeHandler}
                                                            required='required'
                                                            minLength="2"
                                                            maxLength="15"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label" >Last name</label>
                                                        <Inputt
                                                            className="form-control-alternative form-control"
                                                            id="lastName"
                                                            name="lastName"
                                                            type="text"
                                                            placeholder="last name"
                                                            value={lastName}
                                                            onChange={this.changeHandler}
                                                            required='required'
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">Other names</label>
                                                        <Inputt
                                                            className="form-control-alternative form-control"
                                                            id="otherNames"
                                                            name="otherNames"
                                                            type="text"
                                                            placeholder="otherNames"
                                                            value={otherNames}
                                                            onChange={this.changeHandler}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">Email address</label>
                                                        <Inputt
                                                            className="form-control-alternative form-control"
                                                            id="email"
                                                            name="email"
                                                            type="text"
                                                            placeholder="email"
                                                            value={email}
                                                            onChange={this.changeHandler}
                                                            required='required'
                                                        />
                                                    </FormGroup>

                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">Phone number</label>
                                                        <Inputt
                                                            className="form-control-alternative form-control"
                                                            id="phoneNumber"
                                                            name="phoneNumber"
                                                            placeholder="phone number"
                                                            type="text"
                                                            value={phoneNumber}
                                                            onChange={this.changeHandler}
                                                            required='required'
                                                            minLength="11"
                                                            maxLength="15"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">Select school</label>
                                                        <select className="form-control-alternative form-control"
                                                            id="schoolId"
                                                            name="schoolId"
                                                            placeholder="class"
                                                            type="select"
                                                            value={schoolId}
                                                            onChange={this.changeHandler}
                                                        >
                                                            {selectSchoolItems}
                                                        </select>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">Password</label>
                                                        <Inputt
                                                            className="form-control-alternative form-control"
                                                            id="password"
                                                            name="password"
                                                            type="text"
                                                            placeholder="password"
                                                            value={password}
                                                            onChange={this.changeHandler}
                                                            required='required'
                                                        />
                                                    </FormGroup>

                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">Retype password</label>
                                                        <Inputt
                                                            className="form-control-alternative form-control"
                                                            id="confirmPassword"
                                                            name="confirmPassword"
                                                            placeholder="retype password"
                                                            type="text"
                                                            value={confirmPassword}
                                                            onChange={this.changeHandler}
                                                            required='required'
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        <hr className="my-4" />
                                        {/* Address */}
                                        <h6 className="heading-small text-muted mb-4">Contact information</h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup>
                                                        <label className="form-control-label">Address</label>
                                                        <Inputt
                                                            className="form-control-alternative form-control"
                                                            id="Address"
                                                            name="Address"
                                                            placeholder="Home Address"
                                                            type="text"
                                                            value={address}
                                                            onChange={this.changeHandler}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">City</label>
                                                        <Inputt
                                                            className="form-control-alternative form-control"
                                                            id="input-city"
                                                            name="input-city"
                                                            placeholder="City"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">Country</label>
                                                        <Inputt
                                                            className="form-control-alternative form-control"
                                                            id="input-country"
                                                            placeholder="Country"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">Postal code</label>
                                                        <Inputt
                                                            className="form-control-alternative form-control"
                                                            id="input-postal-code"
                                                            placeholder="Postal code"
                                                            type="number"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        <hr className="my-4" />
                                        <Button
                                            type="submit"
                                            color="success"
                                            style={{ float: "right" }}
                                        >
                                            Save
                                    </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default CreateUser