import React, { Component } from 'react'
// core components
import AdminHeader from "components/Headers/AdminHeader.jsx";
import axios from 'axios';
import Inputt, { PageHeader } from 'components/Reusable/PageHeader.jsx'
import { PINTableContent } from 'components/Reusable/TableContent.jsx'
import swal from 'sweetalert'
// reactstrap components
import {
    Card,
    Container,
    Row,
    Button,
    CardBody,
    FormGroup,
    Form,
    Col
} from "reactstrap";


let batchList = []
let batchToDisplay = []
let pindata = []
var pinsToDisplay = []

class PinManagement extends Component {
    constructor() {
        super()
        this.changeHandler = this.changeHandler.bind(this)
        this.generatePINHandler = this.generatePINHandler.bind(this)
        this.populateBatch = this.populateBatch.bind(this)
        this.populatePINSByBatch = this.populatePINSByBatch.bind(this)
    }

    columns = [
        { name: 'S/N', selector: 'SN' },
        { name: "PIN Code", selector: "PINCode" },
        { name: "Serial Number", selector: "SerialNumber" },
        { name: "Is Used", selector: "IsUsed" }
    ]

    state = {
        noOfPIN: '',
        schoolId: '',
        batchNo: '',
        batchid: [],
        batches: [],
        PINS: []
    }

    componentDidMount() {
        this.populateBatch();
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    populateBatch() {
        batchToDisplay = []
        batchToDisplay.push({ BatchNo: '--- SELECT ---' })

        axios.get(`/ResultPIN/getPINBatch`)
            .then(response => {
                if (response.data) {
                    batchList = response.data
                    batchList.map((batch, index) => {
                        batchToDisplay.push({ BatchNo: batch })
                    })
                    this.state.batches = batchToDisplay
                    this.setState({ batches: batchToDisplay })
                }
            })
            .catch(error => {

            })
    }

    populatePINSByBatch() {
        pinsToDisplay = []
        axios.get(`/ResultPIN/getPINSByBatch?batchNo=${this.state.batchid}`)
            .then(response => {
                if (response.data) {
                    pindata = response.data
                    pindata.map((pin, index) => {
                        console.log(pin.isUsed)
                        index = index + 1;
                        pinsToDisplay.push({
                            SN: index, PINCode: pin.pinCode, SerialNumber: pin.serialNumber, IsUsed: pin.isUsed.toString()
                        })
                    })
                    this.setState({ PINS: pinsToDisplay })
                }
            })
            .catch(error => {
                
            })
    }

    generatePINHandler = (e) => {
        e.preventDefault()
        this.state.schoolId = sessionStorage.getItem('schoolId')
        axios.get(`/ResultPIN/GenerateResultPIN?number=${this.state.noOfPIN}&schoolId=${this.state.schoolId}&batchNo=${this.state.batchNo}`)
            .then(response => {
                if (response.data && response.data.status == 1) {
                    swal({
                        title: "Done!",
                        text: response.data.responseMessage,
                        icon: "success",
                        timer: 3000,
                        button: true
                    })
                }
                if (response.data && response.data.status == 0) {
                    swal({
                        title: "Failed!",
                        text: response.data.responseMessage,
                        icon: "info",
                        timer: 3000,
                        button: true
                    })
                }
            })
            .catch(error => {
                swal({
                    title: "Error!",
                    text: "unable to process your request",
                    icon: "error",
                    timer: 3000,
                    button: true
                })
            })
    }

    render() {
        const { noOfPIN, batchNo, batchid } = this.state
        const selectBatchItems = this.state.batches.map((batch, index) => {
            return <option key={batch.BatchNo} value={batch.BatchNo}>
                {batch.BatchNo}
            </option>
        })
        return (
            <>
                <AdminHeader />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <PageHeader heading="Student class information" />
                                <CardBody>
                                    <Form onSubmit={this.generatePINHandler}>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <label className="form-control-label">Number of PINS to generate:</label>
                                                        <Inputt
                                                            className="form-control-alternative form-control"
                                                            id="noOfPIN"
                                                            name="noOfPIN"
                                                            type="number"
                                                            placeholder="number"
                                                            value={noOfPIN}
                                                            onChange={this.changeHandler}
                                                            required='required'
                                                            minLength="1"
                                                            maxLength="4"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <label className="form-control-label">Batch No:</label>
                                                        <Inputt
                                                            className="form-control-alternative form-control"
                                                            id="batchNo"
                                                            name="batchNo"
                                                            type="text"
                                                            placeholder="batch no"
                                                            value={batchNo}
                                                            onChange={this.changeHandler}
                                                            required='required'
                                                            minLength="1"
                                                            maxLength="10"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <label className="form-control-label">.</label><br />
                                                        <Button
                                                            type="submit"
                                                            color="success"
                                                        >
                                                            Generate
                                    </Button>
                                                    </FormGroup>

                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                    <hr />
                                    <Row>
                                        <Col lg="3">
                                            <FormGroup>
                                                <label className="form-control-label">Batch No:</label>
                                                <select className="form-control-alternative form-control"
                                                    id="batchid"
                                                    name="batchid"
                                                    placeholder="class"
                                                    type="select"
                                                    value={batchid}
                                                    onChange={this.changeHandler}
                                                >
                                                    {selectBatchItems}
                                                </select>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="3">
                                            <FormGroup>
                                                <label className="form-control-label">.</label><br />
                                                <Button
                                                    type="button"
                                                    color="success"
                                                    onClick={this.populatePINSByBatch}
                                                >
                                                    View
                                    </Button>
                                            </FormGroup>

                                        </Col>

                                    </Row>
                                    <hr />
                                    <span style={{ paddingTop: "50px" }}>
                                        <Row>
                                            <PINTableContent
                                                columns={this.columns}
                                                data={this.state.PINS}
                                                addLink="#"
                                                addText="#"
                                            />
                                        </Row>
                                    </span>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default PinManagement