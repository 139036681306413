import React from 'react'
// import { Scope } from '@babel/traverse'
import { Link } from 'react-router-dom'

// reactstrap components
import {
    Badge,
    Media
} from "reactstrap";

class School extends React.Component {
    // constructor() {
    //     super()

    // }

    render() {

        const school = this.props.schoolrecord
        const index = this.props.index
        
        return (
            <>
                {/* <li> {school.name} {school.phoneNumber} {school.address}</li> */}

                <tr>
                    <th scope="row">
                        <Media className="align-items-center">
                            <a
                                className="avatar rounded-circle mr-3"
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                            >
                                <img
                                    alt="..."
                                    src={require("assets/img/theme/vue.jpg")}
                                />
                            </a>
                            <Media>
                                <span className="mb-0 text-sm">
                                    <Link to={{pathname: `/admin/schooldetails/${index}`, state:{schoolKey: school.schoolId} }}>{school.name}</Link>
                                </span>
                            </Media>
                        </Media>
                    </th>
                    <td>{school.phoneNumber}</td>
                    <td>
                        <Badge color="" className="badge-dot mr-4">
                            <i className="bg-success" />
                            {school.address}
                        </Badge>
                    </td>
                    <td>
                        <Badge color="" className="badge-dot mr-4">
                            <i className="bg-success" />
                            {school.emailAddress}
                        </Badge>
                    </td>
                    {/* <td className="text-right">
                        <UncontrolledDropdown>
                            <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={e => e.preventDefault()}
                            >
                                <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem
                                    href="#pablo"
                                    onClick={e => e.preventDefault()}
                                >
                                    Action
                                </DropdownItem>
                                <DropdownItem
                                    href="#pablo"
                                    onClick={e => e.preventDefault()}
                                >
                                    Another action
                                </DropdownItem>
                                <DropdownItem
                                    href="#pablo"
                                    onClick={e => e.preventDefault()}
                                >
                                    Something else here
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </td> */}
                </tr>

            </>
        )
    }
}

export default School