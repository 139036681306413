import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import TableContent from 'components/Reusable/TableContent.jsx'
import AdminHeader from "components/Headers/AdminHeader.jsx";
// reactstrap components
import {
    Card,
    Container,
    Row
} from "reactstrap";

let data = []

var subjectToDisplay = []
class Subjects extends Component {
    constructor() {
        super()
    }

    columns = [
        { name: 'S/N', selector: 'SN' },
        { name: "subject", selector: "subject" },
        {
            cell: (row) => <button>
                <Link to={{ pathname: `/admin/subjectdetails/${row.SN}`, state: { subjectKey: row.id } }}>Details</Link>
            </button>, ignoreRowClick: true, button: true,
        }
    ]

    state = {
        subjects: []
    }
    componentDidMount() {
        subjectToDisplay = []
        axios.get("/Subject")
            .then(response => {
                if (response.data) {
                    data = response.data
                    data.map((subject, index) => {
                        index = index + 1;
                        subjectToDisplay.push({ SN: index, id: subject.subjectId, subject: subject.subject })
                    })
                    this.setState({ subjects: subjectToDisplay })
                }
            })
            .catch(error => {
                
            })
    }

    render() {
        return (
            <>
                {/* Page header */}
                <AdminHeader />
                {/* Page content */}
                <TableContent
                    title = "Subjects"
                    columns={this.columns}
                    data={this.state.subjects}
                    addLink="/admin/createsubject"
                    addText="Add Subject"
                />
            </>
        )
    }
}

export default Subjects