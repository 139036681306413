/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import axios from 'axios';

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";
import "assets/styles/stylesheet.css";
import CheckResult from "./components/CheckResult/CheckResult.jsx";
import Login from "./views/examples/Login.jsx";

//import 'bootstrap/dist/css/bootstrap.min.css'

// axios.defaults.baseURL = 'https://localhost/api'
//axios.defaults.baseURL = 'https://localhost:5001/api'
axios.defaults.baseURL = 'http://api.edu-direct.com/api'

let token = sessionStorage.getItem('token')
let roleId = sessionStorage.getItem('roleId')
let schoolId = sessionStorage.getItem('schoolId')
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  axios.defaults.headers.common['roleId'] = `${roleId}`;
  axios.defaults.headers.common['schoolId'] = `${schoolId}`;
}
else {
  delete axios.defaults.headers.common['Authorization'];
}

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/admin" render={props => <AdminLayout {...props} />} />
      <Route path="/auth" render={props => <AuthLayout {...props} />} />
      {/* <Route path="/" component={CheckResult}></Route> */}
      <Route path="/" component={Login}></Route>
      {/* <Redirect from="/" to="/auth/login" /> */}
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
