
import React, { Component } from 'react'

import { Link } from 'react-router-dom'
import DataTable, { memoize } from 'react-data-table-component'
import ExportHeader from 'components/Reusable/ExportHeader.jsx'

// reactstrap components
import {
    Card,
    Container,
    Row
} from "reactstrap";

const customStyles = {
    rows: {
        style: {

        },
    },
    headCells: {
        style: {
            backgroundcolor: '#f6f9fc',
            color: '#8898aa',
            textTransform: 'uppercase'
        },
    },
    cells: {
        style: {

        },
    },
};

class TableContent extends Component {
    render() {
        return (<>

            <Container className="mt--7" fluid>

                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <ExportHeader addLink={this.props.addLink} addText={this.props.addText} dataToExport={this.props.data} />

                            {/* Table */}
                            <DataTable
                                title={this.props.title}
                                columns={this.props.columns}
                                data={this.props.data}
                                striped
                                pagination
                                highlightOnHover
                                noDataComponent="No record to display"
                                className="align-items-center table-flush"
                                responsive
                                filter={true}
                                customStyles={customStyles}
                            />
                        </Card>
                    </div>
                </Row>
            </Container>


        </>)
    }
}

export class PINTableContent extends Component {
    render() {
        return (<>

            <Container className="" fluid>

                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <ExportHeader addLink={this.props.addLink} addText={this.props.addText} dataToExport={this.props.data} />

                            {/* Table */}
                            <DataTable
                                title="Voucher Lists"
                                columns={this.props.columns}
                                data={this.props.data}
                                striped
                                pagination
                                highlightOnHover
                                noDataComponent="No record to display"
                                className="align-items-center table-flush"
                                responsive
                                customStyles={customStyles}
                            />
                        </Card>
                    </div>
                </Row>
            </Container>


        </>)
    }
}

export default TableContent
