import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import TableContent from 'components/Reusable/TableContent.jsx'

import AdminHeader from "components/Headers/AdminHeader.jsx";
// reactstrap components
import {
    Card,
    Container,
    Row
} from "reactstrap";

let data = []

var academicsession = []
class AcademicSessions extends Component {
    constructor() {
        super()
    }

    state = {
        academicSession: []
    }

    componentDidMount() {
        academicsession = []
        axios.get("/Session")
            .then(response => {
                if (response.data) {
                    data = response.data
                    data.map((session, index) => {
                        index = index + 1;
                        academicsession.push({ SN: index, id: session.sessionId, session: session.academicSession })
                    })
                    this.setState({ academicSession: academicsession })
                }
            })
            .catch(error => {
                
            })
    }

    columns = [
        { name: 'S/N', selector: 'SN' },
        { name: "session", selector: "session" },
        {
            cell: (row) => <button>
                <Link to={{ pathname: `/admin/academicsessiondetails/${row.SN}`, state: { sessionKey: row.id } }}>Details</Link>
            </button>, ignoreRowClick: true, button: true,
        }
    ]

    render() {
        return (
            <>
                {/* Page header */}
                <AdminHeader />
                {/* Page content */}
                <TableContent
                    title = "Academic Sessions"
                    columns={this.columns}
                    data={this.state.academicSession}
                    addLink="/admin/createacademicsession"
                    addText="Add Session"
                />
            </>
        )
    }
}

export default AcademicSessions