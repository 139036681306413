/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import axios from 'axios';
import Inputt, { PageHeader } from 'components/Reusable/PageHeader.jsx'
import { Redirect } from 'react-router-dom'

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

class ForgotPassword extends React.Component {

  constructor() {
    super()
    this.changeHandler = this.changeHandler.bind(this)
    this.forgotPassword = this.forgotPassword.bind(this)
  }

  state = {
    errors: {},
    email: '',
    isAuthenticated: false,
    isLoading: false
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

    handleValidation(){
      let errors = {};
      let formIsValid = true;

      // //Name
      // if(!fields["name"]){
      //   formIsValid = false;
      //   errors["name"] = "Cannot be empty";
      // }

      // if(typeof fields["name"] !== "undefined"){
      //   if(!fields["name"].match(/^[a-zA-Z]+$/)){
      //       formIsValid = false;
      //       errors["name"] = "Only letters";
      //   }        
      // }

      //Email
      if(!this.state.email || this.state.email.length < 1){
        formIsValid = false;
        errors["email"] = "Cannot be empty";
      }

      if(this.state.email !== "undefined"){        
        let lastAtPos = this.state.email.lastIndexOf('@');
        let lastDotPos = this.state.email.lastIndexOf('.');
        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.email.indexOf('@@') == -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)) {
            formIsValid = false;
            errors["email"] = "Email is not valid";
          }
    }      

    this.setState({errors: errors});
    return formIsValid;
  }

  forgotPassword = (e) => {
    // e.preventDefault()
    //axios.post("https://localhost:44319/api/user/Authenticate?email=peter@gmil.com&password=pterrre")
    if(this.handleValidation()){
      this.setState({isLoading: true})
    }else{
      return;
   }
    var host = window.location.origin;
    axios.get(`/user/forgotPassword?email=${this.state.email}&weblink=${host}`)
      //axios.post("user/Authenticate", this.state)
      .then(response => {
        this.setState({ isLoading: false })
        if (response && response.status) {
          this.setState({ isAuthenticated: true })        
        }
      })
      .catch(error => {
        this.setState({ isLoading: false })
      })
  }

  render() {
    const { email } = this.state
    let renderPage =
      (<Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Enter your email address</small>
            </div>
            {/* <Form onSubmit={this.ForgotPassword}> */}
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Inputt
                    placeholder="Email"
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={this.changeHandler}
                    required='required' />
                </InputGroup>
                <span style={{color: "red"}}>{this.state.errors["email"]}</span>
              </FormGroup>
              
              <div className="text-center">
                <Button className="my-4" color="primary" type="button" onClick={this.forgotPassword}>
                  Reset Password
              </Button>
              </div>
            {/* </Form> */}
          </CardBody>
        </Card>
        
      </Col>)

    if (this.state.isLoading) {
      renderPage = 
      (<Row className="mt-3">
      <Col xs="12">
        <div className="loader"></div>
      </Col>          
    </Row>)
    }
    if (this.state.isAuthenticated) {
      renderPage = 
      (<Row className="mt-3">
      <Col xs="12">
        <label
          style={{color: "white"}}>
          A password reset link has been sent to your registered email address. Kindly 
          click on the link to reset your password.          
        </label>
      </Col>          
    </Row>)

    }
    return (
      <>
        {renderPage}
      </>
    );
  }
}

export default ForgotPassword;
