import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import TableContent from 'components/Reusable/TableContent.jsx'
import AdminHeader from "components/Headers/AdminHeader.jsx";
import Inputt, { PageHeader } from 'components/Reusable/PageHeader.jsx'
import { ResultPreview, ResultView } from '../Reusable/PageHeader';
import swal from 'sweetalert'

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    FormGroup,
    Form,
    Row,
    Col
} from "reactstrap";

const customStyles = {
    headCells: {
        style: {
            backgroundcolor: '#f6f9fc',
            color: '#8898aa',
            textTransform: 'uppercase'
        },
    },
};

var ResultListPreview = []
var ResultList = []
let columns = []
var firstCAResultComponent = []
var secondCAResultComponent = []
var thirdCAResultComponent = []
var examResultComponent = []
var dbComponent = []
let termList = []
let termToDisplay = []
let subjectList = []
let subjectToDisplay = []
let resultSet = null;

class GenerateResultComponents extends Component {
    constructor() {
        super()
        this.changeHandler = this.changeHandler.bind(this)
        this.createResultHandler = this.createResultHandler.bind(this)
        this.postResultHandler = this.postResultHandler.bind(this)
        this.populateResultHandler = this.populateResultHandler.bind(this)
        this.populateStudentData = this.populateStudentData.bind(this)
        this.removeRecord = this.removeRecord.bind(this)
        this.saveTempResultHandler = this.saveTempResultHandler.bind(this)
        this.populateDbValues = this.populateDbValues.bind(this)
        this.populateTerm = this.populateTerm.bind(this)
        this.populateSubject = this.populateSubject.bind(this)
        this.populateResultTable = this.populateResultTable.bind(this)
        this.ClearResult = this.ClearResult.bind(this)
        this.columns = [
            { name: "SN", selector: "SN" },
            { name: "subject", selector: "subject" },
            { name: "score", selector: "score" }
        ]
    }

    state = {
        registrationNumber: '',
        firstName: '',
        otherNames: '',
        surname: '',
        subject: '',
        score: '',
        schoolAddress: '',
        schoolEmail: '',
        schoolName: '',
        schoolPhoneNumber: '',
        resultListPreview: [],
        resultList: [],
        data: [],
        firstCA: {},
        tabindex: 1,
        saveText: 'Save first CA',
        terms: [],
        subjects: [],
        termId: '',
        resultData: {
            SchoolId: '',
            StudentId: '',
            RegistrationNumber: '',
            SessionId: '341125F0-0437-465B-80EE-08D7A9D40D98',
            TermId: '',
            ResultParameters: "",
            ResultParameterArray: [],
            ResultId: ''
        },

        FirstCA: [],
        SecondCA: [],
        ThirdCA: [],
        ExamCA: []
    }

    componentDidMount() {

        firstCAResultComponent = [{}];
        secondCAResultComponent = [{}];
        thirdCAResultComponent = [{}];
        examResultComponent = [{}];
        dbComponent = [{ first: {} }, { second: {} }, { third: {} }, { exam: {} }]

        this.populateTerm();
        this.populateSubject();
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        if (e.target.name == 'termId') {
            this.state.resultData.TermId = e.target.value
        }
    }

    populateTerm() {
        termToDisplay = []
        termToDisplay.push({ termId: '', termName: '--- SELECT ---' })

        axios.get("/Term")
            .then(response => {
                if (response.data) {
                    termList = response.data
                    termList.map((term, index) => {
                        termToDisplay.push({ termId: term.termId, termName: term.termName })
                    })
                    this.setState({ terms: termToDisplay })
                }
            })
            .catch(error => {

            })
    }

    populateSubject() {
        subjectToDisplay = []
        subjectToDisplay.push({ subjectId: '', subject: '--- SELECT ---' })

        axios.get("/Subject")
            .then(response => {
                if (response.data) {
                    subjectList = response.data
                    subjectList.map((subject, index) => {
                        subjectToDisplay.push({ subjectId: subject.subjectId, subject: subject.subject })
                    })
                    this.setState({ subjects: subjectToDisplay })
                }
            })
            .catch(error => {

            })
    }

    createResultHandler() {
        this.state.resultData.SchoolId = sessionStorage.getItem('schoolId')
        axios.post("/Result", this.state.resultData)
            .then(response => {
                if (response.data && response.data.status == 1) {
                    swal({
                        title: "Done!",
                        text: response.data.responseMessage,
                        icon: "success",
                        timer: 3000,
                        button: true
                    })
                }
                if (response.data && response.data.status == 0) {
                    swal({
                        title: "Failed!",
                        text: response.data.responseMessage,
                        icon: "info",
                        timer: 3000,
                        button: true
                    })
                }
            })
            .catch(error => {
                swal({
                    title: "Error!",
                    text: "unable to process your request",
                    icon: "error",
                    timer: 3000,
                    button: true
                })
            })
    }

    postResultHandler() {
        this.state.resultData.SchoolId = sessionStorage.getItem('schoolId')
        this.state.resultData.ResultParameters = JSON.stringify({ ResultParameters: ResultList });
        axios.post("/Result/postResult", this.state.resultData)
            .then(response => {
                if (response.data && response.data.status == 1) {
                    swal({
                        title: "Done!",
                        text: response.data.responseMessage,
                        icon: "success",
                        timer: 3000,
                        button: true
                    })
                }
                if (response.data && response.data.status == 0) {
                    swal({
                        title: "Feedback!",
                        text: response.data.responseMessage,
                        icon: "info",
                        timer: 3000,
                        button: true
                    })
                }
            })
            .catch(error => {
                swal({
                    title: "Error!",
                    text: "unable to process your request",
                    icon: "error",
                    timer: 3000,
                    button: true
                })
            })
    }


    removeRecord = (e) => {
        e.preventDefault()
        //ResultListPreview.splice(e.target.id - 1, 1)
        if (this.state.tabindex == 0) {
            this.state.FirstCA.splice(e.target.id - 1, 1)
            this.state.resultListPreview = this.state.FirstCA;
            this.setState({ [this.state.resultListPreview]: this.state.FirstCA })
            this.state.FirstCA.map((record, index) => {
                firstCAResultComponent.push({ [record.subject]: record.score })
            })
        }
        if (this.state.tabindex == 1) {
            this.state.FirstCA.splice(e.target.id - 1, 1)
            this.state.resultListPreview = this.state.FirstCA;
            this.setState({ [this.state.resultListPreview]: this.state.FirstCA })
            this.state.FirstCA.map((record, index) => {
                firstCAResultComponent.push({ [record.subject]: record.score })
            })
        }
        if (this.state.tabindex == 2) {
            this.state.SecondCA.splice(e.target.id - 1, 1)
            this.state.resultListPreview = this.state.SecondCA;
            this.setState({ [this.state.resultListPreview]: this.state.SecondCA })
            this.state.SecondCA.map((record, index) => {
                secondCAResultComponent.push({ [record.subject]: record.score })
            })
        }
        if (this.state.tabindex == 3) {
            this.state.ThirdCA.splice(e.target.id - 1, 1)
            this.state.resultListPreview = this.state.ThirdCA;
            this.setState({ [this.state.resultListPreview]: this.state.ThirdCA })
            this.state.ThirdCA.map((record, index) => {
                thirdCAResultComponent.push({ [record.subject]: record.score })
            })
        }
        if (this.state.tabindex == 4) {
            this.state.ExamCA.splice(e.target.id - 1, 1)
            this.state.resultListPreview = this.state.ExamCA;
            this.setState({ [this.state.resultListPreview]: this.state.ExamCA })
            this.state.ExamCA.map((record, index) => {
                examResultComponent.push({ [record.subject]: record.score })
            })
        }
    }

    populateResultHandler = ((e) => {
        e.preventDefault()
        if (!this.state.score.trim() || !this.state.subject) {
            return;
        }
        ResultListPreview.push({ SN: "1", subject: this.state.subject, score: this.state.score })

        if (this.state.tabindex == 0) {
            firstCAResultComponent.push({ [this.state.subject]: this.state.score })
            this.state.FirstCA.push({ SN: "1", subject: this.state.subject, score: this.state.score });
        }
        if (this.state.tabindex == 1) {
            firstCAResultComponent.push({ [this.state.subject]: this.state.score })
            this.state.FirstCA.push({ SN: "1", subject: this.state.subject, score: this.state.score });
        }
        if (this.state.tabindex == 2) {
            secondCAResultComponent.push({ [this.state.subject]: this.state.score })
            this.state.SecondCA.push({ SN: "1", subject: this.state.subject, score: this.state.score });
        }
        if (this.state.tabindex == 3) {
            thirdCAResultComponent.push({ [this.state.subject]: this.state.score })
            this.state.ThirdCA.push({ SN: "1", subject: this.state.subject, score: this.state.score });
        }
        if (this.state.tabindex == 4) {
            examResultComponent.push({ [this.state.subject]: this.state.score })
            this.state.ExamCA.push({ SN: "1", subject: this.state.subject, score: this.state.score });
        }
        if (this.state.tabindex == 5) {
        }

        this.state.resultListPreview = ResultListPreview;
        this.state.subject = ''
        this.state.score = ''
        this.setState({ [this.state.resultListPreview]: ResultListPreview })
        this.setState({ [this.state.score]: '' })
    })

    populateResultTable(currentResult) {
        ResultListPreview = []
        ResultList = []
        resultSet = null
        if (!currentResult) {
            return;
        }
        var obj = JSON.parse(currentResult)
        let obj1 = obj.ResultParameters[0]
        var obj2 = obj.ResultParameters[1]
        var obj3 = obj.ResultParameters[2]
        var obj4 = obj.ResultParameters[3]
        var obj1Array = Object.keys(obj1.first)
        var obj2Array = Object.keys(obj2.second)
        var obj3Array = Object.keys(obj3.third)
        var obj4Array = Object.keys(obj4.exam)
        obj1Array.map((record, index) => {
            ResultListPreview.push({ SN: index, subject: record, score: obj1.first[record] });
            this.state.FirstCA.push({ SN: index, subject: record, score: obj1.first[record] });
        })
        obj2Array.map((record, index) => {
            this.state.SecondCA.push({ SN: index, subject: record, score: obj2.second[record] });
        })
        obj3Array.map((record, index) => {
            this.state.ThirdCA.push({ SN: index, subject: record, score: obj3.third[record] });
        })
        obj4Array.map((record, index) => {
            this.state.ExamCA.push({ SN: index, subject: record, score: obj4.exam[record] });
        })
        this.state.ExamCA.map((record, index) => {
            ResultList.push({
                subject: record.subject,
                firstTest: obj1.first[record.subject],
                secondTest: obj2.second[record.subject],
                thirdTest: obj3.third[record.subject],
                exam: obj4.exam[record.subject],
                totalScore: parseFloat(obj1.first[record.subject]) + parseFloat(obj2.second[record.subject])
                    + parseFloat(obj3.third[record.subject]) + parseFloat(obj4.exam[record.subject])
            })
        })

        this.state.resultList = ResultList;
        this.setState({ [this.state.resultList]: ResultList })

        this.state.resultListPreview = ResultListPreview;
        this.setState({ [this.state.resultListPreview]: ResultListPreview })

        resultSet = this.state.resultList.map((result, index) => {
            return <ResultView index={index} record={result} />
        })

    }

    ClearResult() {
        this.state.firstName = ''
        this.state.otherNames = ''
        this.state.surname = ''
        this.setState({ [this.state.firstName]: '' })
        this.setState({ [this.state.otherNames]: '' })
        this.setState({ [this.state.surname]: '' })
        this.state.ResultListPreview = []
        this.state.FirstCA = []
        this.state.SecondCA = []
        this.state.ThirdCA = []
        this.state.ExamCA = []
        this.setState({ [this.state.ResultListPreview]: [] })
        this.setState({ [this.state.FirstCA]: [] })
        this.setState({ [this.state.SecondCA]: [] })
        this.setState({ [this.state.ThirdCA]: [] })
        this.setState({ [this.state.ExamCA]: [] })
        this.state.resultListPreview = [];
        this.setState({ [this.state.resultListPreview]: [] })

        this.state.schoolAddress  = ''
        this.state.schoolEmail = ''
        this.state.schoolName = ''
        this.state.schoolPhoneNumber = ''

    }

    populateStudentData = ((e) => {
        e.preventDefault();
        this.ClearResult();
        let regNo = this.state.registrationNumber;
        if (!regNo) {
            return;
        }
        axios.get(`/Student/GetByRegistrationNumber?registrationNumber=${regNo}&term=${this.state.resultData.TermId}`)
            .then(response => {
                if (response.data) {
                    this.state.firstName = response.data.firstName
                    this.state.otherNames = response.data.otherNames
                    this.state.surname = response.data.lastName
                    this.state.resultData.RegistrationNumber = response.data.studentRegistrationNumber
                    this.state.resultData.StudentId = response.data.studentId
                    this.state.resultData.ResultId = response.data.resultId
                    this.setState({ [this.state.firstName]: response.data.firstName })
                    this.setState({ [this.state.otherNames]: response.data.otherNames })
                    this.setState({ [this.state.surname]: response.data.surname })
                    this.setState({ [this.state.registrationNumber]: response.data.studentRegistrationNumber })
                    this.setState({ [this.state.resultData.studentId]: response.data.studentId })
                    this.setState({ [this.state.resultData.ResultId]: response.data.resultId })
                    this.setState({ [this.state.resultData.RegistrationNumber]: response.data.studentRegistrationNumber })
                    
                    
                    this.state.schoolAddress = response.data.schoolAddress
                    this.state.schoolEmail = response.data.schoolEmail
                    this.state.schoolName = response.data.schoolName
                    this.state.schoolPhoneNumber = response.data.schoolPhoneNumber

                    this.populateResultTable(response.data.result)
                }
            })
            .catch(error => {

            })
    })

    saveTempResultHandler = ((e) => {
        e.preventDefault()
        ResultList = []
        ResultListPreview.map((record, index) => {
            ResultList.push({ subject: record.subject, firstTest: record.score, seconTest: record.score, thirdTest: record.score, exam: record.score, totalScore: record.score })
        })
        this.state.resultList = ResultList;
        this.setState({ [this.state.resultList]: ResultList })

        this.populateDbValues();
    })

    populateDbValues() {
        dbComponent = [
            {
                first: Object.assign.apply({}, firstCAResultComponent.length == 0 ? [] : [...firstCAResultComponent])
            },
            {
                second: Object.assign.apply({}, secondCAResultComponent.length == 0 ? [] :
                    [...secondCAResultComponent])
            },
            {
                third: Object.assign.apply({}, thirdCAResultComponent.length == 0 ? [] :
                    [...thirdCAResultComponent])
            },
            {
                exam: Object.assign.apply({}, examResultComponent.length == 0 ? [] :
                    [...examResultComponent])
            },
        ]

        this.state.resultData.ResultParameters = JSON.stringify({ ResultParameters: dbComponent });

        this.createResultHandler();
    }

    changeResultCmponent = ((e) => {
        e.preventDefault()
        this.state.tabindex = e.target.tabIndex;
        this.state.saveText = e.target.tabIndex == 1 ? "Save first CA" :
            e.target.tabIndex == 2 ? "Save second CA" :
                e.target.tabIndex == 3 ? "Save third CA" :
                    e.target.tabIndex == 4 ? "Save exam" : "";

        if (this.state.tabindex == 0) {
            this.state.resultListPreview = this.state.FirstCA;
        }
        if (this.state.tabindex == 1) {
            this.state.resultListPreview = this.state.FirstCA;
        }
        if (this.state.tabindex == 2) {
            this.state.resultListPreview = this.state.SecondCA;
        }
        if (this.state.tabindex == 3) {
            this.state.resultListPreview = this.state.ThirdCA;
        }
        if (this.state.tabindex == 4) {
            this.state.resultListPreview = this.state.ExamCA;
        }
        if (this.state.tabindex == 5) {

        }
        this.setState({ [this.state.tabindex]: e.target.tabIndex })
        this.state.resultList = [];
        this.setState({ [this.state.resultList]: [] })

        ResultListPreview = []
    })

    render() {
        const { registrationNumber, firstName, otherNames, surname, subject, termId, score, 
            resultListPreview, schoolAddress, schoolEmail, schoolName, schoolPhoneNumber } = this.state
        const resultSetPreview = this.state.resultListPreview.map((record, index) => {
            index = index + 1
            return <ResultPreview index={index} record={record} removeRecord={this.removeRecord} />
        })
        const selectTermItems = this.state.terms.map((term, index) => {
            return <option key={term.termId} value={term.termId}>
                {term.termName}
            </option>
        })
        const selectSubjectItems = this.state.subjects.map((subject, index) => {
            return <option key={subject.subjectId} value={subject.subject}>
                {subject.subject}
            </option>
        })
        let saveText = this.state.saveText;

        let firstCA =
            (
                < Row >
                    <Form onSubmit={this.createResultHandler}>
                        <div className="pl-lg-4">
                            <Row>
                                <Col lg="4">
                                    <FormGroup>
                                        <label className="form-control-label">Registration number</label>
                                        <Inputt
                                            className="form-control-alternative form-control"
                                            id="registrationNumber"
                                            name="registrationNumber"
                                            type="text"
                                            value={registrationNumber}
                                            onChange={this.changeHandler}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="4">
                                    <FormGroup>
                                        <label className="form-control-label">Term</label>
                                        <select className="form-control-alternative form-control"
                                            id="termId"
                                            name="termId"
                                            placeholder="class"
                                            type="select"
                                            value={termId}
                                            onChange={this.changeHandler}
                                        >
                                            {selectTermItems}
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col lg="4">
                                    <FormGroup>
                                        <label className="form-control-label">.</label>
                                        <Button
                                            onClick={this.populateStudentData}
                                            type="submit"
                                            color="blue"
                                            style={{ width: "100%" }}
                                        >
                                            SEARCH
</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4">
                                    <FormGroup>
                                        <label className="form-control-label">First name</label>
                                        <Inputt
                                            className="form-control-alternative form-control"
                                            id="firstName"
                                            name="firstName"
                                            type="text"
                                            disabled="disabled"
                                            value={firstName}
                                            onChange={this.changeHandler}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="4">
                                    <FormGroup>
                                        <label className="form-control-label">Other names</label>
                                        <Inputt
                                            className="form-control-alternative form-control"
                                            id="otherNames"
                                            name="otherNames"
                                            type="text"
                                            disabled="disabled"
                                            value={otherNames}
                                            onChange={this.changeHandler}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="4">
                                    <FormGroup>
                                        <label className="form-control-label">Surname</label>
                                        <Inputt
                                            className="form-control-alternative form-control"
                                            id="surname"
                                            name="surname"
                                            type="text"
                                            disabled="disabled"
                                            value={surname}
                                            onChange={this.changeHandler}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4">
                                    <FormGroup>
                                        <label className="form-control-label">Subject</label>
                                        <select className="form-control-alternative form-control"
                                            id="subject"
                                            name="subject"
                                            placeholder="class"
                                            type="select"
                                            value={subject}
                                            onChange={this.changeHandler}
                                        >
                                            {selectSubjectItems}
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col lg="4">
                                    <FormGroup>
                                        <label className="form-control-label">Score</label>
                                        <Inputt
                                            className="form-control-alternative form-control"
                                            id="score"
                                            name="score"
                                            type="number"
                                            value={score}
                                            onChange={this.changeHandler}
                                            required='required'
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="4">
                                    <FormGroup>
                                        <label className="form-control-label">.</label>
                                        <Button
                                            onClick={this.populateResultHandler}
                                            type="submit"
                                            color="default"
                                            style={{ width: "100%" }}
                                        >
                                            add to list
</Button>
                                    </FormGroup>
                                </Col>
                            </Row>

                        </div>
                        <hr className="my-4" />

                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">SN</th>
                                    <th scope="col">SUBJECT</th>
                                    <th scope="col">SCORE</th>
                                </tr>
                            </thead>
                            <tbody>

                                {resultSetPreview}

                            </tbody>
                        </Table>

                        <hr className="my-4" />
                        <Row>
                            <Col lg="12">
                                <Button
                                    onClick={this.saveTempResultHandler}
                                    type="button"
                                    color="success"
                                    style={{ width: "100%" }}
                                >
                                    {saveText}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Row >
            );

        if (this.state.tabindex == 5) {

            firstCA =
                (
                    <div>
                        < Row >
                            <Col className="order-xl-1" xl="12">
                                <Card className="bg-secondary shadow">
                                    <PageHeader heading="Result preview" />
                                    <CardBody>
                                        <Row>
                                            <Col lg="12" className="top-result bold" >
                                            {schoolName}
        </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12" className="top-result">
                                            {schoolAddress}
        </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12" className="top-result">
                                                Email: {schoolEmail}, Tel: {schoolPhoneNumber}
        </Col>
                                        </Row>
                                        <hr className="my-4" />
                                        <Row>
                                            <Col lg="12" className="top-result bold">
                                                STUDENT TERM REPORT CARD
        </Col>
                                        </Row>
                                        <hr className="my-4" />
                                        <Row>
                                            <Col lg="3">
                                                Student name:
        </Col>
                                            <Col lg="3">
                                                {firstName} {otherNames} {surname}
        </Col>
                                            <Col lg="3">
                                                Class:
        </Col>
                                            <Col lg="3">
                                                J.S.S 3 Gold
        </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="3">
                                                Registration number:
        </Col>
                                            <Col lg="3">
                                                {registrationNumber}
        </Col>
                                        </Row>
                                        <hr className="my-4" />
                                        <Row className="table-padding">
                                            <Col lg="12">
                                                <Table className="align-items-center table-flush" responsive>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                Subject
                </th>
                                                            <th>
                                                                CA1
                </th>
                                                            <th>
                                                                CA2
                </th>
                                                            <th>
                                                                CA3
                                                            </th>
                                                            <th>
                                                                Examination
                </th>
                                                            <th>
                                                                Total
                </th>
                                                            <th>
                                                                Grade
                </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {resultSet}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                        <hr className="my-4" />
                                        <Row>
                                            <Col lg="4" className="bold">
                                                Head teacher's remark
        </Col>
                                            <Col lg="8">
                                                Good student, pays attention to his academics and sports. Will do very
                                                well in future
        </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4" className="bold">
                                                Principal's remark
        </Col>
                                            <Col lg="8">
                                                Good student, keep it up
        </Col>
                                        </Row>
                                        <Row style={{ paddingTop: "100px" }}>
                                            <Col lg="6">
                                                _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ <br />
                                                Head teacher's signature and date
        </Col>
                                            <Col lg="6" style={{ textAlign: "right" }}>
                                                _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ <br />
                                                Principal's signature and date
        </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row >
                        <Row>
                            <Col lg="12">
                                <Button
                                    onClick={this.postResultHandler}
                                    type="button"
                                    color="warning"
                                    style={{ width: "100%", marginTop: "50px" }}
                                >
                                    POST RESULT
        </Button>
                            </Col>
                        </Row>
                    </div>
                )
        }

        return (
            <>
                <AdminHeader />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <PageHeader heading="Generate result" />
                                <CardBody>
                                    <Row>
                                        <Col lg="2">
                                            < Col lg="12" className="btn-result" tabIndex="1" onClick={this.changeResultCmponent}>
                                                First CA
                    </Col >
                                            < Col lg="12" className="result-btn-line"></Col >
                                            < Col lg="12" className="btn-result" tabIndex="2" onClick={this.changeResultCmponent}>
                                                Second CA
                    </Col >
                                            < Col lg="12" className="result-btn-line"></Col >
                                            {/* < Col lg="12" className="btn-result" tabIndex="3" onClick={this.changeResultCmponent}>
                                                Third CA
                                             </Col > */}
                                            < Col lg="12" className="result-btn-line"></Col >
                                            < Col lg="12" className="btn-result" tabIndex="4" onClick={this.changeResultCmponent}>
                                                Exam
                    </Col >
                                            < Col lg="12" className="result-btn-line"></Col >
                                            < Col lg="12" className="btn-result" tabIndex="5" onClick={this.changeResultCmponent}>
                                                Preview result
                    </Col >

                                        </Col>
                                        <Col lg="10">
                                            {firstCA}
                                        </Col>
                                    </Row>
                                </CardBody>

                            </Card>

                        </Col>

                    </Row>

                </Container>
            </>
        )


    }
}

export default GenerateResultComponents
