/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import axios from 'axios';
import Inputt, { PageHeader } from 'components/Reusable/PageHeader.jsx'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

class Login extends React.Component {

  constructor() {
    super()
    this.changeHandler = this.changeHandler.bind(this)
    // this.authenticate = this.authenticate.bind(this)
    this.checkAuthentication = this.checkAuthentication.bind(this)
  }

  state = {
    errors: {},
    email: '',
    password: '',
    isAuthenticated: false,
    isLoading: false
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

    handleValidation(){
      let errors = {};
      let formIsValid = true;

      //Email
      if(!this.state.email || this.state.email.length < 1){
        formIsValid = false;
        errors["email"] = "Cannot be empty";
      }

      if(this.state.email !== "undefined"){        
        let lastAtPos = this.state.email.lastIndexOf('@');
        let lastDotPos = this.state.email.lastIndexOf('.');
        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.email.indexOf('@@') == -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)) {
            formIsValid = false;
            errors["email"] = "Email is not valid";
          }
    }
    //password
    if(!this.state.password || this.state.password.length < 1){
      formIsValid = false;
      errors["password"] = "password cannot be empty";
    }      

    this.setState({errors: errors});
    return formIsValid;
  }

  authenticate(e){
    e.preventDefault()
    // axios.post("https://localhost:44319/api/user/Authenticate?email=peter@gmil.com&password=pterrre")
      if(this.handleValidation()){
        this.setState({isLoading: true})
      }else{
        return;
    }
    // this.setState({isLoading: true})
    axios.get(`/user/Authenticate?email=${this.state.email}&password=${this.state.password}`)
      //axios.post("user/Authenticate", this.state)
      .then(response => {
        this.setState({ isLoading: false })
        if (response.data.token) {
          sessionStorage.setItem('token', response.data.token);
          sessionStorage.setItem('userId', response.data.userId);
          sessionStorage.setItem('schoolId', response.data.schoolId);
          sessionStorage.setItem('roleId', response.data.roleId);
          sessionStorage.setItem('fullname', `${response.data.firstName} ${response.data.lastName}`);
          this.checkAuthentication();
        }
        else{
            swal({
              title: "login failed!",
              text: "Invalid username or password",
              icon: "error",
              timer: 3000,
              button: true
          })
        }
      })
      .catch(error => {
        this.setState({ isLoading: false })
        swal({
          title: "login failed!",
          text: "Invalid username or password",
          icon: "error",
          timer: 3000,
          button: true
      })
    })
  }

  checkAuthentication() {
    let authenticate = sessionStorage.getItem('token') != null && sessionStorage.getItem('userId') != null;
    this.setState({ isAuthenticated: authenticate })
  }

  componentDidMount() {

  }
  
  render() {
    const { email, password } = this.state
    let renderPage =
      (<Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Enter your credentials</small>
            </div>
            <Form >
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Inputt
                    placeholder="Email"
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={this.changeHandler}
                    required='required' />
                </InputGroup>
                <span style={{color: "red"}}>{this.state.errors["email"]}</span>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Inputt
                    placeholder="Password"
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={this.changeHandler}
                    required='required' />
                </InputGroup>
                <span style={{color: "red"}}>{this.state.errors["password"]}</span>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div>
              <div className="text-center">
                <Button className="my-4" color="primary" type="button" onClick={this.authenticate.bind(this)}>
                  Sign in
              </Button>
              {/* <button onClick={this.authenticate.bind(this)}>Sign in</button> */}
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            
            <Link to="/auth/forgotpassword" style={{ color: "white" }}>Forgot password?</Link>

          </Col>
          {/* <Col className="text-right" xs="6">
          <a
            className="text-light"
            href="#pablo"
            onClick={e => e.preventDefault()}
          >
            <small>Create new account</small>
          </a>
        </Col> */}
        </Row>
      </Col>)

    if (this.state.isLoading) {
      renderPage = 
      (<Row className="mt-3">
      <Col xs="12">
        <div className="loader"></div>
      </Col>          
    </Row>)
    }
    if (this.state.isAuthenticated) {
      renderPage = <Redirect to="/admin/index" />
    }
    return (
      <>
        {renderPage}
      </>
    );
  }
}

export default Login;
