import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import TableContent from 'components/Reusable/TableContent.jsx'

import AdminHeader from "components/Headers/AdminHeader.jsx";
// reactstrap components
import {
    Card,
    Container,
    Row
} from "reactstrap";

let data = []

var gradeToDisplay = []

class Grades extends Component {
    constructor() {
        super()
    }

    columns = [
        { name: 'S/N', selector: 'SN' },
        { name: "grade", selector: "grade" },
        { name: "range-From", selector: "rangeFrom" },
        { name: "range-To", selector: "rangeTo" },
        {
            cell: (row) => <button>
                <Link to={{ pathname: `/admin/gradedetails/${row.SN}`, state: { gradeKey: row.id } }}>Details</Link>
            </button>, ignoreRowClick: true, button: true,
        }
    ]

    state = {
        grades: []
    }
    componentDidMount() {
        gradeToDisplay = []
        // axios.get(`/Grade/GetBySchool?schoolId=${sessionStorage.getItem('schoolId')}`)
        axios.get("/Grade")
            .then(response => {
                if (response.data) {
                    data = response.data
                    data.map((grade, index) => {
                        index = index + 1;
                        gradeToDisplay.push({
                            SN: index, id: grade.gradeId, grade: grade.grade,
                            rangeFrom: grade.rangeFrom, rangeTo: grade.rangeTo
                        })
                    })
                    this.setState({ grades: gradeToDisplay })
                }
            })
            .catch(error => {
                
            })
    }

    render() {
        return (
            <>
                {/* Page header */}
                <AdminHeader />
                {/* Page content */}
                <TableContent
                    title = "Grades"
                    columns={this.columns}
                    data={this.state.grades}
                    addLink="/admin/creategrade"
                    addText="Add Grade"
                />
            </>
        )
    }
}

export default Grades