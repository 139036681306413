import React, { Component } from 'react'
// core components
import AdminHeader from "components/Headers/AdminHeader.jsx";
import axios from 'axios';
import Inputt, { PageHeader } from 'components/Reusable/PageHeader.jsx'
import swal from 'sweetalert'

// reactstrap components
import {
    Card,
    Container,
    Row,
    Button,
    CardBody,
    FormGroup,
    Form,
    Col
} from "reactstrap";

class CreateAcademicSession extends Component {
    constructor() {
        super()
        this.changeHandler = this.changeHandler.bind(this)
        this.createAcademicSessionHandler = this.createAcademicSessionHandler.bind(this)
    }

    state = {
        academicSession: '',
        schoolId:''
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    createAcademicSessionHandler = (e) => {
        e.preventDefault()
        this.state.schoolId = sessionStorage.getItem('schoolId')
        axios.post("/Session", this.state)
            .then(response => {
                if (response.data && response.data.status == 1) {
                    swal({
                        title: "Done!",
                        text: response.data.responseMessage,
                        icon: "success",
                        timer: 3000,
                        button: true
                    })
                }                
                if (response.data && response.data.status == 0) {
                    swal({
                        title: "Failed!",
                        text: response.data.responseMessage,
                        icon: "info",
                        timer: 3000,
                        button: true
                    })
                }
            })
            .catch(error => {
                swal({
                    title: "Error!",
                    text: "unable to process your request",
                    icon: "error",
                    timer: 3000,
                    button: true
                })
            })
    }

    render() {
        const { academicSession } = this.state
        return (
            <>
                <AdminHeader />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <PageHeader heading="Academic session information" />
                                <CardBody>
                                    <Form onSubmit={this.createAcademicSessionHandler}>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">Academic session</label>
                                                        <Inputt
                                                            className="form-control-alternative form-control"
                                                            id="academicSession"
                                                            name="academicSession"
                                                            type="text"
                                                            placeholder="academic session"
                                                            value={academicSession}
                                                            onChange={this.changeHandler}
                                                            required='required'
                                                            minLength="4"
                                                            maxLength="9"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="12">
                                                    <hr className="my-4" />
                                                    <Button
                                                        type="submit"
                                                        color="success"
                                                        style={{ float: "right" }}
                                                    >
                                                        Save
                                    </Button>
                                                </Col>
                                            </Row>
                                        </div></Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default CreateAcademicSession