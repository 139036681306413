import React, { Component } from 'react'
// core components
import AdminHeader from "components/Headers/AdminHeader.jsx";
import axios from 'axios';
import Inputt, { PageHeader } from 'components/Reusable/PageHeader.jsx'
import swal from 'sweetalert'
// reactstrap components
import {
    Card,
    Container,
    Row,
    Button,
    CardBody,
    FormGroup,
    Form,
    Col
} from "reactstrap";

//let formInputs = []
let classList = []
let classToDisplay = []

class CreateStudent extends Component {
    constructor() {
        super()
        this.changeHandler = this.changeHandler.bind(this)
        this.createStudentHandler = this.createStudentHandler.bind(this)
        this.handleValidation = this.handleValidation.bind(this)
    }

    state = {
        studentRegistrationNumber: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: '',
        address: '',
        StudentClassId: '',
        studentClass: [],
        dateOfBirth: '',
        schoolId: '',
        errors: []
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleValidation(rules) {
        let error = []
        this.setState({ errors: error });
        let formIsValid = true;
        // this.formInputs.map((inputRules, index) => {

        // })
        if (rules.required && rules.value.trim().length < 1) {
            error.push({ errorMessage: `${rules.name} is required` })
            formIsValid = false;
            this.state.errors = error
        }
        if (rules.minLength && rules.value.trim().length < rules.minLength) {
            error.push({ errorMessage: `${rules.name} should have a minimum of ${rules.minLength} characters` })
            formIsValid = false;
            this.state.errors = error
        }
        if (!rules.maxLength && rules.value.trim().length > rules.maxLength) {
            error.push({ errorMessage: `${rules.name} should have a maximum of ${rules.maxLength} characters` })
            formIsValid = false;
            this.state.errors = error
        }
        this.setState({ errors: error });
        return formIsValid;
    }

    createStudentHandler = (e) => {
        e.preventDefault()
        this.state.schoolId = sessionStorage.getItem('schoolId')
        // if (!this.handleValidation(this.formInputs)) {
        //     return;
        // }
        axios.post("/student", this.state)
            .then(response => {
                if (response.data && response.data.status == 1) {
                    swal({
                        title: "Done!",
                        text: response.data.responseMessage,
                        icon: "success",
                        timer: 3000,
                        button: true
                    })
                }
                if (response.data && response.data.status == 0) {
                    swal({
                        title: "Failed!",
                        text: response.data.responseMessage,
                        icon: "info",
                        timer: 3000,
                        button: true
                    })
                }
            })
            .catch(error => {
                swal({
                    title: "Error!",
                    text: "unable to process your request",
                    icon: "error",
                    timer: 3000,
                    button: true
                })
            })
    }

    componentDidMount() {
        classToDisplay = []
        classToDisplay.push({ studentClassId: '', className: '--- SELECT ---' })

        axios.get("/StudentClass")
            .then(response => {
                if (response.data) {
                    classList = response.data
                    classList.map((classes, index) => {
                        classToDisplay.push({ studentClassId: classes.studentClassId, className: classes.className })
                    })
                    this.setState({ studentClass: classToDisplay })
                }
            })
            .catch(error => {
                
            })
    }

    render() {
        const { studentRegistrationNumber, firstName, lastName, emailAddress, phoneNumber, address,
            dateOfBirth, StudentClassId } = this.state
        const selectClassItems = this.state.studentClass.map((studentClass, index) => {
            return <option key={studentClass.studentClassId} value={studentClass.studentClassId}>
                {studentClass.className}
            </option>
        })
        return (
            <>
                <AdminHeader />

                {/* Page content */}
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <PageHeader heading="Student information" />
                                <CardBody>
                                    <Form onSubmit={this.createStudentHandler}>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">Registration number</label>
                                                        <Inputt
                                                            className="form-control-alternative form-control"
                                                            id="studentRegistrationNumber"
                                                            name="studentRegistrationNumber"
                                                            type="text"
                                                            placeholder="Reg number"
                                                            value={studentRegistrationNumber}
                                                            onChange={this.changeHandler}
                                                            required='required'
                                                            minLength="2"
                                                            maxLength="15"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">First name</label>
                                                        <Inputt
                                                            className="form-control-alternative form-control"
                                                            id="firstName"
                                                            name="firstName"
                                                            type="text"
                                                            placeholder="first name"
                                                            value={firstName}
                                                            onChange={this.changeHandler}
                                                            required='required'
                                                            minLength="2"
                                                            maxLength="15"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label" >Last name</label>
                                                        <Inputt
                                                            className="form-control-alternative form-control"
                                                            id="lastName"
                                                            name="lastName"
                                                            type="text"
                                                            placeholder="last name"
                                                            value={lastName}
                                                            onChange={this.changeHandler}
                                                            required='required'
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">Email address</label>
                                                        <Inputt
                                                            className="form-control-alternative form-control"
                                                            id="emailAddress"
                                                            name="emailAddress"
                                                            type="text"
                                                            placeholder="email"
                                                            value={emailAddress}
                                                            onChange={this.changeHandler}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">Phone number</label>
                                                        <Inputt
                                                            className="form-control-alternative form-control"
                                                            id="phoneNumber"
                                                            name="phoneNumber"
                                                            placeholder="phone number"
                                                            type="text"
                                                            value={phoneNumber}
                                                            onChange={this.changeHandler}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">Class</label>
                                                        {/* <Inputt
                                                            className="form-control-alternative form-control"
                                                            id="StudentClassId"
                                                            name="StudentClassId"
                                                            placeholder="class"
                                                            type="select"
                                                            value={StudentClassId}
                                                            onChange={this.changeHandler}
                                                            selectitems={selectClassItems}
                                                        /> */}
                                                        <select className="form-control-alternative form-control"
                                                            id="StudentClassId"
                                                            name="StudentClassId"
                                                            placeholder="class"
                                                            type="select"
                                                            value={StudentClassId}
                                                            onChange={this.changeHandler}
                                                            required='required'
                                                        >
                                                            {selectClassItems}
                                                        </select>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">Date of birth</label>
                                                        <Inputt
                                                            className="form-control-alternative form-control"
                                                            id="dateOfBirth"
                                                            name="dateOfBirth"
                                                            placeholder="DoB"
                                                            type="date"
                                                            value={dateOfBirth}
                                                            onChange={this.changeHandler}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        <hr className="my-4" />
                                        {/* Address */}
                                        <h6 className="heading-small text-muted mb-4">Contact information</h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup>
                                                        <label className="form-control-label">Address</label>
                                                        <Inputt
                                                            className="form-control-alternative form-control"
                                                            id="Address"
                                                            name="Address"
                                                            placeholder="Home Address"
                                                            type="text"
                                                            value={address}
                                                            onChange={this.changeHandler}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">City</label>
                                                        <Inputt
                                                            className="form-control-alternative form-control"
                                                            id="input-city"
                                                            name="input-city"
                                                            placeholder="City"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">Country</label>
                                                        <Inputt
                                                            className="form-control-alternative form-control"
                                                            id="input-country"
                                                            placeholder="Country"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">Postal code</label>
                                                        <Inputt
                                                            className="form-control-alternative form-control"
                                                            id="input-postal-code"
                                                            placeholder="Postal code"
                                                            type="number"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        <hr className="my-4" />
                                        <Button
                                            type="submit"
                                            color="success"
                                            style={{ float: "right" }}
                                        >
                                            Save
                                    </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default CreateStudent