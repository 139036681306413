import React, { Component } from 'react'
// core components
import AdminHeader from "components/Headers/AdminHeader.jsx";
import axios from 'axios';// reactstrap components
import swal from 'sweetalert'
import {
    Card,
    CardHeader,
    Container,
    Row,
    Button,
    CardBody,
    FormGroup,
    Form,
    Input,
    Col
} from "reactstrap";

class SubjectDetails extends Component {
    constructor() {
        super()

        this.handleEditButton = this.handleEditButton.bind(this)
        this.changeHandler = this.changeHandler.bind(this)
        this.editSubjectHandler = this.editSubjectHandler.bind(this)

        this.state = {
            subjectId: '',
            subject: '',
            schoolId:'',
            disabled: true,
            visibility: 'hidden'
        }
    }

    handleEditButton() {
        this.setState({ disabled: !this.state.disabled })
        this.setState({ visibility: "hidden" ? "visible" : "hidden" })
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    componentDidMount() {
        const subjectKey = this.props.location.state.subjectKey
        axios.get(`/Subject/${subjectKey}`)
            .then(response => {
                this.state.subject = response.data.subject
                this.state.schoolId = response.data.schoolId
                this.state.subjectId = response.data.subjectId
                this.setState({ [this.state.subject]: response.data.subject })
                this.setState({ [this.state.schoolId]: response.data.schoolId })
                this.setState({ [this.state.subjectId]: response.data.subjectId })
            })
            .catch(error => {

            })
    }

    editSubjectHandler = (e) => {
        e.preventDefault()
        axios.put(`/Subject/${this.state.subjectId}`, this.state)
            .then(response => {
                if (response.data && response.data.status == 1) {
                    swal({
                        title: "Done!",
                        text: response.data.responseMessage,
                        icon: "success",
                        timer: 3000,
                        button: true
                    })

                }
                if (response.data && response.data.status == 0) {
                    swal({
                        title: "Failed!",
                        text: response.data.responseMessage,
                        icon: "info",
                        timer: 3000,
                        button: true
                    })
                }
            })
            .catch(error => {
                swal({
                    title: "Error!",
                    text: "unable to process your request",
                    icon: "error",
                    timer: 3000,
                    button: true
                })
            })
    }

    render() {
        const { subject } = this.state
        return (
            <>
                <AdminHeader />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">

                        </Col>
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Subject information</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">

                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={this.editSubjectHandler}>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">
                                                            Subject
                            </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="subject"
                                                            name="subject"
                                                            type="text"
                                                            value={subject}
                                                            onChange={this.changeHandler}
                                                            disabled={(this.state.disabled) ? "disabled" : ""}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            </div>
                                        <hr className="my-4" />
                                        <Button
                                            type="submit"
                                            color="success"
                                            style={{ float: "right", visibility: this.state.visibility }}
                                        >
                                            Save
                      </Button>
                                        <Button
                                            type="button"
                                            color="success"
                                            style={{ float: "left" }}
                                            onClick={this.handleEditButton}
                                        >
                                            Edit
                      </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default SubjectDetails