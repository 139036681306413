import React, { Component } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom'
import TableContent from 'components/Reusable/TableContent.jsx'

// reactstrap components
import {
    Card,
    Container,
    Row
} from "reactstrap";
// core components
import AdminHeader from "components/Headers/AdminHeader.jsx";

let data = []

var userData = []
var usr = []

class Users extends Component {
    constructor() {
        super()
    }

    state = {
        users: []
    }

    componentDidMount() {
        console.log("component mounted")
        usr = []
        axios.get("/user")
            .then(response => {
                console.log("going to API")
                if (response.data) {
                    console.log("back from api")
                    data = response.data
                    data.map((user, index) => {
                        index = index + 1;
                        userData = {
                            SN: index,
                            firstname: user.firstName,
                            lastname: user.lastName,
                            emailAddress: user.email,
                            telephone: user.phoneNumber,
                            id: user.userId
                        }
                        usr.push(userData)
                    })
                    this.setState({ users: usr })
                }
            })
            .catch(error => {
                console.log("back from api with error: " + error)
            })
    }

    columns =
        [
            { name: 'S/N', selector: 'SN' },
            { name: 'First Name', selector: 'firstname', sortable: true },
            { name: 'Last Name', selector: 'lastname', sortable: true },
            { name: 'Email Address', selector: 'emailAddress', sortable: false },
            { name: 'Telephone number', selector: 'telephone', sortable: false },
            {
                cell: (row) => <button>
                    {/* <Link to={{ pathname: `/admin/userdetails/${row.id}` }}>Details</Link> */}

                    <Link to={{ pathname: `/admin/userdetails/${row.SN}`, state: { userKey: row.id } }}>Details</Link>
                </button>, ignoreRowClick: true, button: true,
            }
        ]

    render() {
        return (
            <>
                {/* Page header */}
                <AdminHeader />
                {/* Page content */}
                <TableContent
                    title = "Users"
                    columns={this.columns}
                    data={this.state.users}
                    addLink="/admin/createuser"
                    addText="Add user"
                />
            </>
        )
    }
}

export default Users