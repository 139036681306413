/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.jsx";
import Profile from "views/examples/Profile.jsx";
import Login from "views/examples/Login.jsx";
import Schools from "../src/components/school/Schools.jsx"
import Users from "./components/User/Users.jsx"
import Students from "./components/student/Students.jsx"
import PinManagement from "./components/PinManagement/PinManagement.jsx";
import GenerateResultComponents from "./components/Result/GenerateResultComponents.jsx";
import CheckResult from "./components/CheckResult/CheckResult.jsx";
import ResetPassword from "./components/User/ResetPassword.jsx"
import ForgotPassword from "./components/User/ForgotPassword.jsx"

import Icons from "views/examples/Icons.jsx";

// var routes = [
//   {
//     path: "/login",
//     name: "Login",
//     icon: "ni ni-key-25 text-info",
//     component: Login,
//     layout: "/auth"
//   },
//   {
//     path: "/",
//     name: "Check Result",
//     icon: "ni ni-key-25 text-info",
//     component: CheckResult,
//     layout: ""
//   }
// ];

// let authenticate = sessionStorage.getItem('token') != null && sessionStorage.getItem('userId') != null;
// if (authenticate) {
var routes = [
  // {
  //   path: "/Icons",
  //   name: "",
  //   component: Icons,
  //   layout: "/admin"
  // },
  {
    path: "/login",
    name: "",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/resetpassword",
    name: "",
    component: ResetPassword,
    layout: "/auth"
  },
  {
    path: "/forgotPassword",
    name: "",
    component: ForgotPassword,
    layout: "/auth"
  },
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin"
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin"
  },
  {
    path: "/schools",
    name: "Schools",
    icon: "ni ni-building text-pink",
    component: Schools,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Users",
    icon: "ni ni-circle-08 text-gray",
    component: Users,
    layout: "/admin"
  },
  {
    path: "/students",
    name: "Students",
    icon: "ni ni-badge text-red",
    component: Students,
    layout: "/admin"
  },
  {
    path: "/generateresult",
    name: "Result Management",
    icon: "ni ni-books text-purple",
    component: GenerateResultComponents,
    layout: "/admin"
  },
  {
    path: "/pinmanagement",
    name: "Pin Management",
    icon: "ni ni-key-25 text-info",
    component: PinManagement,
    layout: "/admin"
  },
  {
    path: "/",
    name: "Check Result",
    icon: "ni ni-world-2 text-success",
    component: CheckResult,
    layout: "/auth"
  }
];

// }
export default routes;
