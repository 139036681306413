import React from 'react'
import axios from 'axios';
import AdminHeader from "components/Headers/AdminHeader.jsx";
import swal from 'sweetalert'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
// core components

class SchoolDetails extends React.Component {
  constructor() {
    super()
    this.handleEditButton = this.handleEditButton.bind(this)
    this.changeHandler = this.changeHandler.bind(this)
  }
  state = {
    schoolId: '',
    name: '',
    address: '',
    phoneNumber: '',
    emailAddress: '',
    disabled: true,
    visibility: 'hidden'
  }

  componentDidMount() {
    const schoolKey = this.props.location.state.schoolKey
    axios.get(`/School/${schoolKey}`)
      .then(response => {
        this.state.schoolId = response.data.schoolId
        this.state.name = response.data.name
        this.state.address = response.data.address
        this.state.phoneNumber = response.data.phoneNumber
        this.state.emailAddress = response.data.emailAddress
        this.setState({ [this.state.schoolId]: response.data.schoolId })
        this.setState({ [this.state.name]: response.data.name })
        this.setState({ [this.state.address]: response.data.address })
        this.setState({ [this.state.emailAddress]: response.data.emailAddress })
        this.setState({ [this.state.phoneNumber]: response.data.phoneNumber })
      })
      .catch(error => {

      })
  }

  handleEditButton() {
    this.setState({ disabled: !this.state.disabled })
    this.setState({ visibility: "hidden" ? "visible" : "hidden" })
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  editSchoolHandler = (e) => {
    e.preventDefault()
    axios.put(`/School/${this.state.schoolId}`, this.state)
      .then(response => {
        if (response.data && response.data.status == 1) {
          swal({
              title: "Done!",
              text: response.data.responseMessage,
              icon: "success",
              timer: 3000,
              button: true
          })
      }
      if (response.data && response.data.status == 0) {
          swal({
              title: "Failed!",
              text: response.data.responseMessage,
              icon: "info",
              timer: 3000,
              button: true
          })
      }
  })
  .catch(error => {
      swal({
          title: "Error!",
          text: "unable to process your request",
          icon: "error",
          timer: 3000,
          button: true
      })
  })
  }

  render() {  
    const { name, address, emailAddress, phoneNumber } = this.state
    if (name) {
      return (<>
        <AdminHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="8">
              <Form onSubmit={this.editSchoolHandler}>
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <Button
                          type="button"
                          color="info"
                          onClick={this.handleEditButton}
                        >
                          Edit
                      </Button>
                      </Col>
                      <Col className="text-right" xs="4">
                        <Button
                          type="submit"
                          color="success"
                          style={{ float: "right", visibility: this.state.visibility }}
                        >
                          Save
                      </Button>
                      </Col>

                    </Row>
                  </CardHeader>
                  <CardBody>
                    <h6 className="heading-small text-muted mb-4">
                      School information
                      </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Name
                              </label>
                            <Input
                              className="form-control-alternative"
                              id="name"
                              name="name"
                              type="text"
                              disabled="disabled"
                              value={name}
                              onChange={this.changeHandler}
                              disabled={(this.state.disabled) ? "disabled" : ""}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          {/*<FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Logo
                              </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            type="text"
                            disabled="disabled"
                          />
                        </FormGroup>*/}
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Phone number
                              </label>
                            <Input
                              className="form-control-alternative"
                              id="phoneNumber"
                              name="phoneNumber"
                              type="text"
                              disabled="disabled"
                              value={phoneNumber}
                              onChange={this.changeHandler}
                              disabled={(this.state.disabled) ? "disabled" : ""}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Email address
                              </label>
                            <Input
                              className="form-control-alternative"
                              id="emailAddress"
                              name="emailAddress"
                              type="email"
                              disabled="disabled"
                              value={emailAddress}
                              onChange={this.changeHandler}
                              disabled={(this.state.disabled) ? "disabled" : ""}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                      Contact information
                      </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Address
                              </label>
                            <Input
                              className="form-control-alternative"
                              id="address"
                              name="address"
                              type="text"
                              disabled="disabled"
                              value={address}
                              onChange={this.changeHandler}
                              disabled={(this.state.disabled) ? "disabled" : ""}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              City
                              </label>
                            <Input
                              className="form-control-alternative"
                              id="input-city"
                              type="text"
                              disabled="disabled"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Country
                              </label>
                            <Input
                              className="form-control-alternative"
                              id="input-country"
                              type="text"
                              disabled="disabled"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Postal code
                              </label>
                            <Input
                              className="form-control-alternative"
                              id="input-postal-code"
                              type="number"
                              disabled="disabled"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </>
      )
    }
    return null;
  }
}

export default SchoolDetails