
import React, { Component } from 'react'
import { CSVLink, CSVDownload } from 'react-csv'
import { Link } from 'react-router-dom'
import {
    Button,
    Card,
    CardFooter,
    CardHeader
} from "reactstrap";


export class ExportHeader extends Component {
    render() {
        const dataToExport = this.props.dataToExport;
        const link = this.props.addLink;
        const linkText = this.props.addText;
        return (
            <>
                <CardHeader className="border-0">
                    <div className="btn btn-default">
                        {/* <h3 className="mb-0">Students Lists</h3> */}
                        <CSVLink data={dataToExport} style={{ color: "white" }}>Export</CSVLink >
                    </div>
                    <div className="btn btn-default" style={{ float: "right" }}>
                        <Link to={link} style={{ color: "white" }}>{linkText}</Link>
                    </div>
                </CardHeader>
            </>)

    }
}

export default ExportHeader