/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import AdminFooter from "components/Footers/AdminFooter.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import SchoolDetails from 'components/school/SchoolDetails.jsx'
import CreateSchool from 'components/school/CreateSchool.jsx'

import routes from "routes.js";
import CreateStudent from "../components/student/CreateStudent";
import StudentDetails from "../components/student/StudentDetails";
import AcademicSessions from "../components/AcademicSession/AcademicSessions.jsx";
import StudentClasses from "../components/StudentClass/StudentClasses.jsx";
import Subjects from "../components/Subject/Subjects.jsx";
import Grades from "../components/Grade/Grades.jsx";
import CreateUser from "../components/User/CreateUser";
import CreateAcademicSession from "../components/AcademicSession/CreateAcademicSession";
import CreateStudentClass from "../components/StudentClass/CreateStudentClass";
import CreateSubject from "../components/Subject/CreateSubject";
import CreateGrade from "../components/Grade/CreateGrade";
import GradeDetails from "../components/Grade/GradeDetails";
import AcademicSessionDetails from "../components/AcademicSession/AcademicSessionDetails";
import StudentClassDetails from "../components/StudentClass/StudentClassDetails";
import SubjectDetails from "../components/Subject/SubjectDetails";
import UserDetails from "../components/User/UserDetails";
import Index from "views/Index.jsx";

class Admin extends React.Component {
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  render() {
    let authenticate = sessionStorage.getItem('token') != null && sessionStorage.getItem('userId') != null;
    let SidebarNavigation = null;
    if (authenticate) {
      SidebarNavigation = (<Sidebar
        {...this.props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("assets/img/brand/result-image.jpg"),
          imgAlt: "..."
        }}
      />)
    }
    return (
      <>
        {SidebarNavigation}
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            {this.getRoutes(routes)}
            <Route path="/admin/schooldetails/:id" render={(params) => (
              <SchoolDetails {...params} />
            )} />
            <Route path="/admin/studentdetails/:id" render={(params) => (
              <StudentDetails {...params} />
            )} />
            <Route path="/admin/gradedetails/:id" render={(params) => (
              <GradeDetails {...params} />
            )} />
            {/* <Route path="/admin/academicsessiondetails/:id" render={(params) => (
              <AcademicSessionDetails {...params} />
            )} /> */}
            <Route path="/admin/classdetails/:id" render={(params) => (
              <StudentClassDetails {...params} />
            )} />
            <Route path="/admin/subjectdetails/:id" render={(params) => (
              <SubjectDetails {...params} />
            )} />
            <Route path="/admin/userdetails/:id" render={(params) => (
              <UserDetails {...params} />
            )} />
            {/* <Route path="/admin/index" component={Index}></Route> */}
            <Route path="/admin/academicsession" component={AcademicSessions}></Route>
            <Route path="/admin/studentclasses" component={StudentClasses}></Route>
            <Route path="/admin/subjects" component={Subjects}></Route>
            <Route path="/admin/grades" component={Grades}></Route>
            <Route path="/admin/createuser" component={CreateUser}></Route>
            <Route path="/admin/createschool" component={CreateSchool}></Route>
            <Route path="/admin/createstudent" component={CreateStudent}></Route>
            <Route path="/admin/createacademicsession" component={CreateAcademicSession}></Route>
            <Route path="/admin/createstudentclass" component={CreateStudentClass}></Route>
            <Route path="/admin/createsubject" component={CreateSubject}></Route>
            <Route path="/admin/creategrade" component={CreateGrade}></Route>

          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

export default Admin;
