import React, { Component } from 'react'
// core components
import AdminHeader from "components/Headers/AdminHeader.jsx";
import axios from 'axios';// reactstrap components
import swal from 'sweetalert'

import {
    Card,
    CardHeader,
    Container,
    Row,
    Button,
    CardBody,
    FormGroup,
    Form,
    Input,
    Col
} from "reactstrap";

class UserDetails extends Component {
    constructor() {
        super()

        this.handleEditButton = this.handleEditButton.bind(this)
        this.changeHandler = this.changeHandler.bind(this)
        this.editUserHandler = this.editUserHandler.bind(this)

        this.state = {
            userId: '',
            firstName: '',
            lastName: '',
            otherNames: '',
            email: '',
            phoneNumber: '',
            address: '',
            password: 'password',
            confirmPassword: 'password',

            dateOfBirth: '2019-01-06T17:16:40',
            nationality: '',
            stateOfOrigin: '',
            religion: '',
            registeredBy: '',
            schoolId: '',
            profilePicture: '',
            timeStampCreated: '2019-01-06T17:16:40',
            roleId: '3FA85F64-5717-4562-B3FC-2C963F66AFA6',
            role: '',
            disabled: true,
            visibility: 'hidden'
        }
    }

    handleEditButton() {
        this.setState({ disabled: !this.state.disabled })
        this.setState({ visibility: "hidden" ? "visible" : "hidden" })
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    componentDidMount() {
        const userKey = this.props.location.state.userKey
        axios.get(`/User/${userKey}`)
            .then(response => {
                this.state.userId = response.data.userId
                this.state.firstName = response.data.firstName
                this.state.lastName = response.data.lastName
                this.state.otherNames = response.data.otherNames
                this.state.email = response.data.email
                this.state.phoneNumber = response.data.phoneNumber
                this.state.nationality = response.data.nationality
                this.state.registeredBy = '00000000-0000-0000-0000-000000000000'
                this.setState({ [this.state.firstName]: response.data.firstName })
                this.setState({ [this.state.lastName]: response.data.lastName })
                this.setState({ [this.state.otherNames]: response.data.otherNames })
                this.setState({ [this.state.email]: response.data.email })
                this.setState({ [this.state.phoneNumber]: response.data.phoneNumber })
                this.setState({ [this.state.nationality]: response.data.nationality })
                this.setState({ [this.state.userId]: response.data.userId })
                this.setState({ [this.state.registeredBy]: '00000000-0000-0000-0000-000000000000' })
            })
            .catch(error => {

            })
    }

    editUserHandler = (e) => {
        e.preventDefault()
        this.state.schoolId = sessionStorage.getItem('schoolId')
        axios.put(`/User/${this.state.userId}`, this.state)
            .then(response => {
                if (response.data && response.data.status == 1) {
                    swal({
                        title: "Done!",
                        text: response.data.responseMessage,
                        icon: "success",
                        timer: 3000,
                        button: true
                    })
                }
                if (response.data && response.data.status == 0) {
                    swal({
                        title: "Failed!",
                        text: response.data.responseMessage,
                        icon: "info",
                        timer: 3000,
                        button: true
                    })
                }
            })
            .catch(error => {
                console.log(this.state)
                swal({
                    title: "Error!",
                    text: "unable to process your request",
                    icon: "error",
                    timer: 3000,
                    button: true
                })
            })
    }

    render() {
        const { firstName, lastName, otherNames, email, phoneNumber, nationality } = this.state
        if ({ firstName }) {

            return (
                <>
                    <AdminHeader />
                    {/* Page content */}
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">

                            </Col>
                            <Col className="order-xl-1" xl="12">
                                <Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
                                        <Row className="align-items-center">
                                            <Col xs="8">
                                                <h3 className="mb-0">User information</h3>
                                            </Col>
                                            <Col className="text-right" xs="4">

                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Form onSubmit={this.editUserHandler}>
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label className="form-control-label">
                                                                First name
                        </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="firstName"
                                                                name="firstName"
                                                                type="text"
                                                                value={this.state.firstName}
                                                                onChange={this.changeHandler}
                                                                disabled={(this.state.disabled) ? "disabled" : ""}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label className="form-control-label">
                                                                Last name
                        </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="lastName"
                                                                name="lastName"
                                                                type="text"
                                                                value={lastName}
                                                                onChange={this.changeHandler}
                                                                disabled={(this.state.disabled) ? "disabled" : ""}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label className="form-control-label">
                                                                Other names
                        </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="otherNames"
                                                                name="otherNames"
                                                                type="text"
                                                                value={otherNames}
                                                                onChange={this.changeHandler}
                                                                disabled={(this.state.disabled) ? "disabled" : ""}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label className="form-control-label">
                                                                Email address
                        </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="email"
                                                                name="email"
                                                                type="text"
                                                                value={email}
                                                                onChange={this.changeHandler}
                                                                disabled={(this.state.disabled) ? "disabled" : ""}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label className="form-control-label">
                                                                Phone number
                        </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="phoneNumber"
                                                                name="phoneNumber"
                                                                type="text"
                                                                value={phoneNumber}
                                                                onChange={this.changeHandler}
                                                                disabled={(this.state.disabled) ? "disabled" : ""}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <hr className="my-4" />
                                            {/* Address */}
                                            <h6 className="heading-small text-muted mb-4">
                                                Contact information
                </h6>
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col md="12">
                                                        <FormGroup>
                                                            <label className="form-control-label">
                                                                Address
                        </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="nationality"
                                                                name="nationality"
                                                                type="text"
                                                                value={nationality}
                                                                onChange={this.changeHandler}
                                                                disabled={(this.state.disabled) ? "disabled" : ""}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label className="form-control-label">
                                                                City
                        </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-city"
                                                                type="text"
                                                                disabled={(this.state.disabled) ? "disabled" : ""}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label className="form-control-label">
                                                                Country
                        </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-country"
                                                                type="text"
                                                                disabled={(this.state.disabled) ? "disabled" : ""}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label className="form-control-label">
                                                                Postal code
                        </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-postal-code"
                                                                type="number"
                                                                disabled={(this.state.disabled) ? "disabled" : ""}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <hr className="my-4" />
                                            <Button
                                                type="submit"
                                                color="success"
                                                style={{ float: "right", visibility: this.state.visibility }}
                                            >
                                                Save
                  </Button>
                                            <Button
                                                type="button"
                                                color="success"
                                                style={{ float: "left" }}
                                                onClick={this.handleEditButton}
                                            >
                                                Edit
                  </Button>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </>
            )
        }
        return null
    }
}

export default UserDetails