import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import TableContent from 'components/Reusable/TableContent.jsx'

import AdminHeader from "components/Headers/AdminHeader.jsx";
// reactstrap components
import {
    Card,
    Container,
    Row
} from "reactstrap";

let data = []

var classToDisplay = []
class StudentClasses extends Component {
    constructor() {
        super()
    }

    columns = [
        { name: 'S/N', selector: 'SN' },
        { name: "class", selector: "class" },
        {
            cell: (row) => <button>
                <Link to={{ pathname: `/admin/classdetails/${row.SN}`, state: { classKey: row.id } }}>Details</Link>
            </button>, ignoreRowClick: true, button: true,
        }
    ]

    state = {
        classes: []
    }
    componentDidMount() {
        classToDisplay = []
        axios.get("/StudentClass")
            .then(response => {
                if (response.data) {
                    data = response.data
                    data.map((classes, index) => {
                        index = index + 1;
                        classToDisplay.push({ SN: index, id: classes.studentClassId, class: classes.className })
                    })
                    this.setState({ classes: classToDisplay })
                }
            })
            .catch(error => {

            })
    }

    render() {
        return (
            <>
                {/* Page header */}
                <AdminHeader />
                {/* Page content */}
                <TableContent
                    title = "Class List"
                    columns={this.columns}
                    data={this.state.classes}
                    addLink="/admin/createstudentclass"
                    addText="Add Class"
                />
            </>
        )
    }
}

export default StudentClasses