import React from 'react'
import axios from 'axios';
import swal from 'sweetalert'

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Col
} from "reactstrap";
// core components
import AdminHeader from "components/Headers/AdminHeader.jsx";

class CreateSchool extends React.Component {
  constructor() {
    super()
    this.createSchoolHandler = this.createSchoolHandler.bind(this)
    this.changeHandler = this.changeHandler.bind(this)
    this.state = {
      Name: '',
      Address: '',
      phoneNumber: '',
      emailAddress: '',
      logo: '',
      colour: ''
    }
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  createSchoolHandler = (e) => {
    e.preventDefault()
    axios.post("/School", this.state)
      .then(response => {
        if (response.data && response.data.status == 1) {
          swal({
            title: "Done!",
            text: response.data.responseMessage,
            icon: "success",
            timer: 3000,
            button: true
          })
        }
        if (response.data && response.data.status == 0) {
            swal({
                title: "Failed!",
                text: response.data.responseMessage,
                icon: "info",
                timer: 3000,
                button: true
            })
        }
      })
      .catch(error => {
        swal({
          title: "Error!",
          text: "unable to process your request",
          icon: "error",
          timer: 3000,
          button: true
        })
      })
  }

  render() {
    const { name, address, phoneNumber, emailAddress, logo, colour } = this.state
    return (
      <>
        <AdminHeader />
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">

            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">School information</h3>
                    </Col>
                    <Col className="text-right" xs="4">

                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.createSchoolHandler}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="Name"
                              name="Name"
                              placeholder="school name"
                              type="text"
                              value={name}
                              onChange={this.changeHandler}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Logo
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="logo"
                              name="logo"
                              type="text"
                              value={logo}
                              onChange={this.changeHandler}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Phone number
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="phoneNumber"
                              name="phoneNumber"
                              placeholder="First name"
                              type="text"
                              value={phoneNumber}
                              onChange={this.changeHandler}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Email address
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="emailAddress"
                              name="emailAddress"
                              placeholder="Last name"
                              type="email"
                              value={emailAddress}
                              onChange={this.changeHandler}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                      Contact information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Address
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="Address"
                              name="Address"
                              placeholder="Home Address"
                              type="text"
                              value={address}
                              onChange={this.changeHandler}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              City
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-city"
                              placeholder="City"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Country
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-country"
                              placeholder="Country"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Postal code
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-postal-code"
                              placeholder="Postal code"
                              type="number"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    <Button
                      type="submit"
                      color="success"
                      style={{ float: "right" }}
                    >
                      Save
                      </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default CreateSchool