import React, { Component } from 'react'
import axios from 'axios';
import School from '../school/School'
import { Link } from 'react-router-dom'
import ExportHeader from 'components/Reusable/ExportHeader.jsx'

// reactstrap components
import {
    Button,
    Badge,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip
} from "reactstrap";
// core components
import AdminHeader from "components/Headers/AdminHeader.jsx";

const data = []

class Schools extends Component {

    constructor() {
        super()
    }

    state = {
        schools: []
    }
    componentDidMount() {
        axios.get("/School")
            .then(response => {
                this.setState({ schools: response.data })
            })
    }

    render() {

        const schoolList = this.state.schools.map((school, index) => {
            return <School key={index} schoolrecord={school} index={index} />
        });

        return (
            <>
                <AdminHeader />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                            <ExportHeader addLink="/admin/createschool" addText="Add School" dataToExport={this.state.schools} />
                                {/* <CardHeader className="border-0">
                                    <div style={{ float: "left" }}>
                                        <h3 className="mb-0">Schools List</h3>
                                    </div>
                                    <div style={{ float: "right" }}>
                                        <Link to="/admin/createschool">Add School</Link>
                                        <Button
                                            color="info"
                                            href="#pablo"
                                            onClick={e => e.preventDefault()}
                                        >
                                            Add School
                                        </Button>

                                    </div>
                                </CardHeader> */}
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">School Name</th>
                                            <th scope="col">Address</th>
                                            <th scope="col">Phone number</th>
                                            <th scope="col">Email</th>
                                            {/* <th scope="col">Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {schoolList}

                                    </tbody>
                                </Table>

                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

export default Schools