/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import axios from 'axios';
import Inputt, { PageHeader } from 'components/Reusable/PageHeader.jsx'
import { Redirect } from 'react-router-dom'
import swal from 'sweetalert'

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

class ResetPassword extends React.Component {

  constructor() {
    super()
    this.changeHandler = this.changeHandler.bind(this)
    this.resetPassword = this.resetPassword.bind(this)
  }

  state = {
    errors: {},
    password: '',
    confirmpassword: '',
    resetsuccessfully: false,
    isLoading: false
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

    handleValidation(){
      let errors = {};
      let formIsValid = true;

    //password
    if(!this.state.password || this.state.password.length < 1){
      formIsValid = false;
      errors["password"] = "password cannot be empty";
    }      

    //confirm password
    if(!this.state.confirmpassword || this.state.confirmpassword.length < 1){
      formIsValid = false;
      errors["confirmpassword"] = "confirm password cannot be empty";
    }      

    this.setState({errors: errors});
    return formIsValid;
  }

  resetPassword = (e) => {
    // e.preventDefault()
    //axios.post("https://localhost:44319/api/user/Authenticate?email=peter@gmil.com&password=pterrre")
      if(this.handleValidation()){
        this.setState({isLoading: true})
      }else{
        return;
    }

    var url_string = window.location.href; 
    var url = new URL(url_string);
    var accessCode = url.searchParams.get("accesscode");

    axios.get(`/user/resetPassword?password=${this.state.password}&confirmpassword=${this.state.confirmpassword}&accessCode=${accessCode}`)
      //axios.post("user/Authenticate", this.state)
      .then(response => {
        this.setState({ isLoading: false })
        if (response.data && response.data.status == 1) {
            swal({
              title: "Done!",
              text: response.data.responseMessage,
              icon: "success",
              timer: 3000,
              button: true
          })  
          this.setState({ resetsuccessfully: true })
        }
        if (response.data && response.data.status == 0) {
            swal({
                title: "Failed!",
                text: response.data.responseMessage,
                icon: "error",
                timer: 3000,
                button: true
            })
        }
        
      })
      .catch(error => {
        this.setState({ isLoading: false })
      })
  }

  render() {
    const { password, confirmpassword } = this.state
    let renderPage =
      (<Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Enter your new password details</small>
            </div>
            {/* <Form onSubmit={this.resetPassword}> */}
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Inputt
                    placeholder="enter password"
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={this.changeHandler}
                    required='required' />
                </InputGroup>
                <span style={{color: "red"}}>{this.state.errors["password"]}</span>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Inputt
                    placeholder="confirm password"
                    type="password"
                    id="confirmpassword"
                    name="confirmpassword"
                    value={confirmpassword}
                    onChange={this.changeHandler}
                    required='required' />
                </InputGroup>                
                <span style={{color: "red"}}>{this.state.errors["confirmpassword"]}</span>
              </FormGroup>
              <div className="text-center">
                <Button className="my-4" color="primary" type="button" onClick={this.resetPassword}>
                  Reset Password
              </Button>
              </div>
            {/* </Form> */}
          </CardBody>
        </Card>
        
      </Col>)
    if (this.state.isLoading) {
      renderPage = 
      (<Row className="mt-3">
      <Col xs="12">
        <div className="loader"></div>
      </Col>          
    </Row>)
    }
    if (this.state.resetsuccessfully) {
      renderPage = <Redirect to="/auth/login" />
    }
    return (
      <>
        {renderPage}
      </>
    );
  }
}

export default ResetPassword;
