import React, { Component } from 'react'
// import { CSVLink, CSVDownload } from 'react-csv'
// import { Link } from 'react-router-dom'

// reactstrap components
import {
    Badge,
    CardHeader,
    Row,
    Col
} from "reactstrap";

export class PageHeader extends Component {
    render() {
        return (
            <>
                <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                        <Col xs="8">
                            <h3 className="mb-0">{this.props.heading}</h3>
                        </Col>
                    </Row>
                </CardHeader>
            </>
        )
    }
}

export const Inputt = (props) => {
    let inputElement = null;
    switch (props.type) {
        case ('text'):
            inputElement = <input  {...props} />;
            break;
        case ('textarea'):
            inputElement = <input {...props} />;
            break;
        case ('select'):
            inputElement = <select {...props} >
                <option key="1">1</option>
                <option key="2">2</option>
            </select>;
            break;
        default:
            inputElement = <input {...props} />
    }

    return (
        <div>
            {inputElement}
        </div>
    )
}

export class ResultPreview extends Component {
    render() {
        const index = this.props.index
        const record = this.props.record
        const removeRecord = this.props.removeRecord
        return (
            <>
                <tr>
                    <td>
                        <Badge color="" className="badge-dot mr-4">
                            {index}
                        </Badge>
                    </td>
                    <td>
                        <Badge color="" className="badge-dot mr-4">
                            <i className="bg-success" />
                            {record.subject}
                        </Badge>
                    </td>
                    <td>
                        <Badge color="" className="badge-dot mr-4">
                            <i className="bg-success" />
                            {record.score}
                        </Badge>
                    </td>
                    <td>
                        {
                            <button id={index} name={record.subject} onClick={removeRecord}>
                                Remove
                            </button>
                        }
                    </td>
                </tr>

            </>
        )
    }
}


export class ResultView extends Component {
    render() {
        const index = this.props.index
        const record = this.props.record
        let grade = "";
        let totalScore = "";
        // totalScore = +record.firstTest + +record.secondTest + +record.thirdTest + +record.exam
        totalScore = +record.firstTest + +record.secondTest + +record.exam;
        if (totalScore > 69) {
            grade = "A"
        }
        else if (totalScore >= 60 && totalScore <= 69) {
            grade = "B"
        }
        else if (totalScore >= 50 && totalScore <= 59) {
            grade = "C"
        }
        else if (totalScore >= 40 && totalScore <= 49) {
            grade = "D"
        }
        else if (totalScore >= 30 && totalScore <= 39) {
            grade = "E"
        }
        else if (totalScore < 30) {
            grade = "F"
        }
        return (
            <>
                <tr>
                    <td>
                        {record.subject}
                    </td>
                    <td>
                        {record.firstTest}
                    </td>
                    <td>
                        {record.secondTest}
                    </td>
                    <td>
                        {record.thirdTest}
                    </td>
                    <td>
                        {record.exam}
                    </td>
                    <td>
                        {/* {record.totalScore} */}
                        {totalScore}
                    </td>
                    <td>
                        {grade}
                    </td>
                </tr>

            </>
        )
    }
}

export default Inputt

// 'text'
//   | 'email'
//   | 'select'
//   | 'file'
//   | 'radio'
//   | 'checkbox'
//   | 'textarea'
//   | 'button'
//   | 'reset'
//   | 'submit'
//   | 'date'
//   | 'datetime-local'
//   | 'hidden'
//   | 'image'
//   | 'month'
//   | 'number'
//   | 'range'
//   | 'search'
//   | 'tel'
//   | 'url'
//   | 'week'
//   | 'password'
//   | 'datetime'
//   | 'time'
//   | 'color';