import React, { Component } from 'react'
// core components
import AdminHeader from "components/Headers/AdminHeader.jsx";
import axios from 'axios';// reactstrap components
import swal from 'sweetalert'
import {
    Card,
    CardHeader,
    Container,
    Row,
    Button,
    CardBody,
    FormGroup,
    Form,
    Input,
    Col
} from "reactstrap";

class GradeDetails extends Component {
    constructor() {
        super()

        this.handleEditButton = this.handleEditButton.bind(this)
        this.changeHandler = this.changeHandler.bind(this)
        this.editGradeHandler = this.editGradeHandler.bind(this)

        this.state = {
            gradeId: '',
            grade: '',
            rangeFrom: '',
            rangeTo: '',
            schoolId:'',
            disabled: true,
            visibility: 'hidden'
        }
    }

    handleEditButton() {
        this.setState({ disabled: !this.state.disabled })
        this.setState({ visibility: "hidden" ? "visible" : "hidden" })
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    
    componentDidMount() {
        const gradeKey = this.props.location.state.gradeKey
        axios.get(`/Grade/${gradeKey}`)
            .then(response => {
                this.state.gradeId = response.data.gradeId
                this.state.grade = response.data.grade
                this.state.rangeFrom = response.data.rangeFrom
                this.state.rangeTo = response.data.rangeTo
                this.state.schoolId = response.data.schoolId
                this.setState({ [this.state.gradeId]: response.data.gradeId })
                this.setState({ [this.state.grade]: response.data.grade })
                this.setState({ [this.state.rangeFrom]: response.data.rangeFrom })
                this.setState({ [this.state.rangeTo]: response.data.rangeTo })
                this.setState({ [this.state.schoolId]: response.data.schoolId })
            })
            .catch(error => {

            })
    }

    editGradeHandler = (e) => {
        e.preventDefault()
        axios.put(`/Grade/${this.state.gradeId}`, this.state)
            .then(response => {
                if (response.data && response.data.status == 1) {
                    swal({
                        title: "Done!",
                        text: response.data.responseMessage,
                        icon: "success",
                        timer: 3000,
                        button: true
                    })
                }                
                if (response.data && response.data.status == 0) {
                    swal({
                        title: "Failed!",
                        text: response.data.responseMessage,
                        icon: "info",
                        timer: 3000,
                        button: true
                    })
                }
            })
            .catch(error => {
                swal({
                    title: "Error!",
                    text: "unable to process your request",
                    icon: "error",
                    timer: 3000,
                    button: true
                })
            })
    }

    render() {
        const { grade, rangeFrom, rangeTo } = this.state
        return (
            <>
                <AdminHeader />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">

                        </Col>
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Grade information</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">

                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={this.editGradeHandler}>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">
                                                            Grade
                            </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="grade"
                                                            name="grade"
                                                            type="text"
                                                            value={grade}
                                                            onChange={this.changeHandler}
                                                            disabled={(this.state.disabled) ? "disabled" : ""}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-username"
                                                        >
                                                            Start range
                            </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="rangeFrom"
                                                            name="rangeFrom"
                                                            type="number"
                                                            value={rangeFrom}
                                                            onChange={this.changeHandler}
                                                            disabled={(this.state.disabled) ? "disabled" : ""}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-username"
                                                        >
                                                            to
                            </label>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-username"
                                                        >
                                                            End range
                            </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="rangeTo"
                                                            name="rangeTo"
                                                            type="number"
                                                            value={rangeTo}
                                                            onChange={this.changeHandler}
                                                            disabled={(this.state.disabled) ? "disabled" : ""}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        <hr className="my-4" />
                                        <Button
                                            type="submit"
                                            color="success"
                                            style={{ float: "right", visibility: this.state.visibility }}
                                        >
                                            Save
                      </Button>
                                        <Button
                                            type="button"
                                            color="success"
                                            style={{ float: "left" }}
                                            onClick={this.handleEditButton}
                                        >
                                            Edit
                      </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default GradeDetails