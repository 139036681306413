import React, { Component } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom'
import TableContent from 'components/Reusable/TableContent.jsx'

// reactstrap components
import {
    Card,
    Container,
    Row
} from "reactstrap";
// core components
import AdminHeader from "components/Headers/AdminHeader.jsx";

let data = []

var schoolData = []
var stdent = []

class Students extends Component {
    constructor() {
        super()
    }

    state = {
        students: []
    }

    componentDidMount() {
        stdent = []
        axios.get("/student")
            .then(response => {
                if (response.data) {
                    data = response.data
                    data.map((student, index) => {
                        index = index + 1;
                        schoolData = {
                            SN: index,
                            studentId: student.studentId,
                            RegNo: student.studentRegistrationNumber,
                            firstname: student.firstName,
                            lastname: student.lastName,
                            telephone: student.phoneNumber
                        }
                        stdent.push(schoolData)
                    })
                    this.setState({ students: stdent })
                }
            })
            .catch(error => {
                
            })
    }

    columns =
        [
            { name: 'S/N', selector: 'SN' },
            { name: 'Registration Number', selector: 'RegNo' },
            { name: 'First Name', selector: 'firstname', sortable: true },
            { name: 'Last Name', selector: 'lastname', sortable: true },
            { name: 'Telephone number', selector: 'telephone', sortable: false },
            {
                cell: (row) => <button>
                    <Link to={{ pathname: `/admin/studentdetails/${row.SN}`, state: { studentKey: row.studentId } }}>Details</Link>
                </button>, ignoreRowClick: true, button: true,
            }
        ]

    render() {
        return (
            <>
                {/* Page header */}
                <AdminHeader />
                {/* Page content */}
                <TableContent
                    title = "Students List"
                    columns={this.columns}
                    data={this.state.students}
                    addLink="/admin/createstudent"
                    addText="Add Student"
                />
            </>
        )
    }
}

export default Students