import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import TableContent from 'components/Reusable/TableContent.jsx'
import AdminHeader from "components/Headers/AdminHeader.jsx";
import Inputt, { PageHeader } from 'components/Reusable/PageHeader.jsx'
import { ResultView } from '../Reusable/PageHeader';
import swal from 'sweetalert'

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Table,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Media
} from "reactstrap";


let termList = []
let termToDisplay = []

let sessionList = []
let sessionToDisplay = []
let resultSet = null;

class CheckResult extends Component {
    constructor() {
        super()
        this.checkResult = this.checkResult.bind(this)
        this.populateSession = this.populateSession.bind(this)
        this.populateTerm = this.populateTerm.bind(this)
        this.changeHandler = this.changeHandler.bind(this)
        this.computeResult = this.computeResult.bind(this)
    }

    state = {
        terms: [],
        academicsessions: [],
        termId: '',
        academicSessionId: '',
        showResult: 'hide',
        resultParameters: [],
        resultList: [],
        resultData: {
            result: '',
            fullName: '',
            voucherPIN: '',
            registrationNumber: '',
            schoolName: '',
            schoolAddress: '',
            schoolEmail: '',
            schoolPhoneNumber: '',
            schoolWebsite: '',
            studentClass: '',
            resultTerm: '',
            resultSession: '',
            status: ''
        }
    }

    componentDidMount() {
        this.populateSession();
        this.populateTerm();
    }

    populateSession() {
        sessionToDisplay = []
        sessionToDisplay.push({ academicSessionId: '', academicSession: '--- SELECT ---' })

        axios.get("/Session")
            .then(response => {
                if (response.data) {
                    sessionList = response.data
                    sessionList.map((asession, index) => {
                        sessionToDisplay.push({ academicSessionId: asession.sessionId, academicSession: asession.academicSession })
                    })
                    this.setState({ academicsessions: sessionToDisplay })
                }
            })
            .catch(error => {
                
            })
    }

    populateTerm() {
        termToDisplay = []
        termList = []
        termToDisplay.push({ termId: '', termName: '--- SELECT ---' })
        axios.get("/Term")
            .then(response => {
                if (response.data) {
                    termList = response.data
                    termList.map((term, index) => {
                        termToDisplay.push({ termId: term.termId, termName: term.termName })
                    })
                    this.setState({ terms: termToDisplay })
                }
            })
            .catch(error => {

            })
    }


    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    checkResult = ((e) => {
        e.preventDefault();

        axios.post(`/Result/checkResult`, this.state)
            .then(response => {
                if (response.data && response.data.status == "valid") {
                    this.state.resultData = response.data
                    this.setState({ [this.state.resultData]: response.data })
                    this.computeResult(this.state.resultData.result)
                }                
                else if (response.data && response.data.status !== "valid") {
                    swal({
                        title: "Done!",
                        text: response.data.status,
                        icon: "info",
                        timer: 5000,
                        button: true
                    })

                }
            })
            .catch(error => {

            })
    })

    computeResult(currentResult) {
        resultSet = null
        if (!currentResult) {
            return;
        }
        var obj = JSON.parse(currentResult)
        this.state.resultList = obj.ResultParameters;
        this.setState({ [this.state.resultList]: obj.ResultParameters })

        resultSet = this.state.resultList.map((res, index) => {
            return <ResultView index={index} record={res} />
        })
        this.setState({ showResult: "hide" ? "show" : "hide" })
    }

    render() {
        const { termId, academicSessionId, studentRegistrationNumber, voucherPIN } = this.state
        const { result, fullName, registrationNumber, schoolName, schoolAddress, schoolEmail, status,
            schoolPhoneNumber, schoolWebsite, studentClass, resultTerm, resultSession } = this.state.resultData
        const selectTermItems = this.state.terms.map((term, index) => {
            return <option key={term.termId} value={term.termId}>
                {term.termName}
            </option>
        })
        const selectAcademicSessionItems = this.state.academicsessions.map((asession, index) => {
            return <option key={asession.academicSessionId} value={asession.academicSessionId}>
                {asession.academicSession}
            </option>
        })
        let resultview = (
            <div className="row">
                <div className="col-lg-3" style={{ fontSize: "15px" }}>
                    <label style={{ fontWeight: "bold" }}>How to</label>
                    <hr />
                    <ol>
                        <li>
                            select year of examination
                            </li>
                        <li>
                            select term
                            </li>
                        <li>
                            enter your registration number
                            </li>
                        <li>
                            enter 10 digit result voucher pin
                            </li>
                        <li>
                            click on submit to view result
                            </li>
                    </ol>
                    <label style={{ fontWeight: "bold" }}>Support lines</label>
                    <div>
                        <label>08080808080, 08080808080</label>
                    </div>
                    <label style={{ fontWeight: "bold" }}>Support emails</label>
                    <div>
                        <label>support@urosoft.com, contact@urosoft.com</label>
                    </div>
                </div>
                <div className="col-lg-4">
                    <label style={{ fontWeight: "bold" }}>Check result</label>
                    <hr />
                    <div>
                        <CardBody>

                            <div className="pl-lg-12">
                                <Row>
                                    <div className="col-lg-4">
                                        <label className="form-control-label">Exam year</label>
                                    </div>
                                    <Col lg="8">
                                        <FormGroup>
                                            <select className="form-control-alternative form-control"
                                                id="academicSessionId"
                                                name="academicSessionId"
                                                placeholder="Year"
                                                type="select"
                                                value={academicSessionId}
                                                onChange={this.changeHandler}
                                                style={{ height: "35px" }}
                                            >
                                                {selectAcademicSessionItems}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className="col-lg-4">
                                        <label className="form-control-label">Term</label>
                                    </div>
                                    <Col lg="8">

                                        <FormGroup>
                                            <select className="form-control-alternative form-control"
                                                id="termId"
                                                name="termId"
                                                placeholder="class"
                                                type="select"
                                                value={termId}
                                                onChange={this.changeHandler}
                                            >
                                                {selectTermItems}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className="col-lg-4">
                                        <label className="form-control-label">Registration number</label>
                                    </div>
                                    <Col lg="8">
                                        <FormGroup>
                                            <Inputt
                                                className="form-control-alternative form-control"
                                                id="studentRegistrationNumber"
                                                name="studentRegistrationNumber"
                                                type="text"
                                                value={studentRegistrationNumber}
                                                onChange={this.changeHandler}
                                                required='required'
                                                minLength="2"
                                                maxLength="15"
                                                style={{ height: "35px" }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className="col-lg-4">
                                        <label className="form-control-label">Voucher e-pin</label>
                                    </div>
                                    <Col lg="8">
                                        <FormGroup>
                                            <Inputt
                                                className="form-control-alternative form-control"
                                                id="voucherPIN"
                                                name="voucherPIN"
                                                type="text"
                                                value={voucherPIN}
                                                onChange={this.changeHandler}
                                                required='required'
                                                minLength="10"
                                                maxLength="10"
                                                style={{ height: "35px" }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                            <hr className="my-4" />
                            <Button
                                type="button"
                                color="success"
                                style={{ float: "right" }}
                                onClick={this.checkResult}
                            >
                                View
                                    </Button>

                        </CardBody>
                    </div>
                </div>
                <div className="col-lg-4">
                    <Media className="">
                        <span className="">
                            {<img
                                alt="..."
                                src={require("assets/img/brand/result-image.jpg")}
                                style={{ maxWidth: "100%" }}
                            />}
                        </span>
                    </Media>
                </div>
            </div>
        );
        if (this.state.showResult == "show") {
            resultview = (
                <div>
                    < Row >
                        <Col xl="2"></Col>
                        <Col className="order-xl-1" xl="8">
                            <Card className="bg-secondary shadow">
                                <PageHeader heading="Student's Result" />
                                <CardBody>
                                    <Row>
                                        <Col lg="12" className="top-result bold" >
                                            {schoolName}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" className="top-result">
                                            {schoolAddress}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" className="top-result">
                                            Email: {schoolEmail}, Tel: {schoolPhoneNumber}
                                        </Col>
                                    </Row>
                                    <hr className="my-4" />
                                    <Row>
                                        <Col lg="12" className="top-result bold">
                                            Student {resultTerm} Report Card, {resultSession} Academic Session
                                        </Col>
                                    </Row>
                                    <hr className="my-4" />
                                    <Row>
                                        <Col lg="3">
                                            Student name:
    </Col>
                                        <Col lg="3">
                                            {fullName}
                                        </Col>
                                        <Col lg="3">
                                            Class:
    </Col>
                                        <Col lg="3">
                                            J.S.S 3 Gold
    </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="3">
                                            Reg. number:
    </Col>
                                        <Col lg="3">
                                            {registrationNumber}
                                        </Col>
                                    </Row>
                                    <hr className="my-4" />
                                    <Row className="table-padding">
                                        <Col lg="12">
                                            <Table className="align-items-center table-flush" responsive>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Subject
            </th>
                                                        <th>
                                                            CA1
            </th>
                                                        <th>
                                                            CA2
            </th>
                                                        <th>
                                                            CA3
            </th>
                                                        <th>
                                                            Examination
            </th>
                                                        <th>
                                                            Total
            </th>
                                                        <th>
                                                            Grade
            </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {resultSet}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                    <hr className="my-4" />
                                    <Row>
                                        <Col lg="4" className="bold">
                                            Head teacher's remark
    </Col>
                                        <Col lg="8">
                                            Good student, pays attention to his academics and sports. Will do very
                                            well in future
    </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="4" className="bold">
                                            Principal's remark
    </Col>
                                        <Col lg="8">
                                            Good student, keep it up
    </Col>
                                    </Row>
                                    {/* <Row style={{ paddingTop: "100px" }}>
                                        <Col lg="6">
                                            _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ <br />
                                            Head teacher's signature and date
    </Col>
                                        <Col lg="6" style={{ textAlign: "right" }}>
                                            _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ <br />
                                            Principal's signature and date
    </Col>
                                    </Row> */}
                                    <Row style={{ paddingTop: "50px" }}>
                                        <Col lg="12">
                                            <span style={{ fontSize: "12px" }}>
                                                This is a system generated result, no signature required,
                                                </span>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row >
                </div>
            )
        }
        return (<>

            <div className="container">
                {/*<div class="row mb-5">
                    <div class="col-12 text-center pt-3">
                        <Media className="">
                            <span className="">
                                {<img
                                    alt="..."
                                    src={require("assets/img/brand/result-image.jpg")}
                                    style = {{maxWidth: "100%"}}
                                />}
                            </span>                            
                        </Media>
                    </div>
                </div>*/}

                <div className="row mb-5">
                    <div className="col-12 py-4 bg-dark">
                        <div className="row">
                            <div className="col-md-3 col-lg-2 pr-md-0">
                                <div className="p-2 bg-primary text-white text-center breaking-caret">
                                    <span className="font-weight-bold">News Update</span></div>
                            </div>
                            <div className="col-md-9 col-lg-10 pl-md-4 py-2">
                                <div className="breaking-box">
                                    <div id="carouselbreaking" className="carousel slide" data-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item">
                                                <a href="https://bootstrap.news/bootstrap-4-template-news-portal-magazine/"><span className="position-relative mx-2 badge badge-primary rounded-0">Technology</span></a> <a className="text-white" href="https://bootstrap.news/bootstrap-4-template-news-portal-magazine/">Google Employees Protest Secret Work on Censored Search Engine for China</a>
                                            </div>
                                            <div className="carousel-item">
                                                <a href="https://bootstrap.news/bootstrap-4-template-news-portal-magazine/"><span className="position-relative mx-2 badge badge-primary rounded-0">Automotive</span></a> <a className="text-white" href="https://bootstrap.news/bootstrap-4-template-news-portal-magazine/">Investors Betting Against Tesla Made $1 Billion on Friday</a>
                                            </div>
                                            <div className="carousel-item">
                                                <a href="https://bootstrap.news/bootstrap-4-template-news-portal-magazine/"><span className="position-relative mx-2 badge badge-primary rounded-0">Football</span></a> <a className="text-white" href="https://bootstrap.news/bootstrap-4-template-news-portal-magazine/">World cup 2050 will release in Dubay</a>
                                            </div>
                                            <div className="carousel-item">
                                                <a href="https://bootstrap.news/bootstrap-4-template-news-portal-magazine/"><span className="position-relative mx-2 badge badge-primary rounded-0">Beauty</span></a> <a className="text-white" href="https://bootstrap.news/bootstrap-4-template-news-portal-magazine/">Lemon make your skin fresh and glowing</a>
                                            </div>
                                            <div className="carousel-item active">
                                                <a href="https://bootstrap.news/bootstrap-4-template-news-portal-magazine/"><span className="position-relative mx-2 badge badge-primary rounded-0">Sport</span></a> <a className="text-white" href="https://bootstrap.news/bootstrap-4-template-news-portal-magazine/">You can now view results on mobile phones. Simply download the app from playstore</a>
                                            </div>
                                        </div>
                                        <div className="navigation-box p-2 d-none d-sm-block">
                                            <a className="carousel-control-prev text-primary" href="#carouselbreaking" role="button" data-slide="prev">
                                                <i className="fa fa-angle-left" aria-hidden="true"></i>
                                                <span className="sr-only">Previous</span>
                                            </a>
                                            <a className="carousel-control-next text-primary" href="#carouselbreaking" role="button" data-slide="next">
                                                <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                <span className="sr-only">Next</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {resultview}

            </div>
        </>)
    }
}

export default CheckResult