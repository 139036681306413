import React, { Component } from 'react'
// core components
import AdminHeader from "components/Headers/AdminHeader.jsx";
import axios from 'axios';// reactstrap components
import swal from 'sweetalert'
import {
    Card,
    CardHeader,
    Container,
    Row,
    Button,
    CardBody,
    FormGroup,
    Form,
    Input,
    Col
} from "reactstrap";

class StudentDetails extends Component {
    constructor() {
        super()

        this.handleEditButton = this.handleEditButton.bind(this)
        this.changeHandler = this.changeHandler.bind(this)
        this.editStudentHandler = this.editStudentHandler.bind(this)

        this.state = {
            studentRegistrationNumber: '',
            studentId: '',
            firstName: '',
            lastName: '',
            otherNames: '',
            emailAddress: '',
            phoneNumber: '',
            studentclass: '',
            studentClassId: '',
            dateOfBirth: '',
            disabled: true,
            visibility: 'hidden'
        }
    }

    componentDidMount() {
        const studentKey = this.props.location.state.studentKey
        axios.get(`/student/${studentKey}`)
            .then(response => {
                this.state.studentId = response.data.studentId
                this.state.firstName = response.data.firstName
                this.state.lastName = response.data.lastName
                this.state.otherNames = response.data.otherNames
                this.state.emailAddress = response.data.emailAddress
                this.state.phoneNumber = response.data.phoneNumber
                this.state.dateOfBirth = response.data.dateOfBirth
                this.state.studentClassId = response.data.studentClassId
                this.state.studentRegistrationNumber = response.data.studentRegistrationNumber
                this.setState({ [this.state.firstName]: response.data.firstName })
                this.setState({ [this.state.lastName]: response.data.lastName })
                this.setState({ [this.state.otherNames]: response.data.otherNames })
                this.setState({ [this.state.emailAddress]: response.data.emailAddress })
                this.setState({ [this.state.phoneNumber]: response.data.phoneNumber })
                this.setState({ [this.state.studentId]: response.data.studentId })
                this.setState({ [this.state.dateOfBirth]: response.data.dateOfBirth })
                this.setState({ [this.state.studentClassId]: response.data.studentClassId })
                this.setState({ [this.state.studentRegistrationNumber]: response.data.studentRegistrationNumber })
            })
            .catch(error => {
                
            })
    }

    handleEditButton() {
        this.setState({ disabled: !this.state.disabled })
        this.setState({ visibility: "hidden" ? "visible" : "hidden" })
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    editStudentHandler = (e) => {
        e.preventDefault()
        axios.put(`/Student/${this.state.studentId}`, this.state)
            .then(response => {
                if (response.data && response.data.status == 1) {
                    swal({
                        title: "Done!",
                        text: response.data.responseMessage,
                        icon: "success",
                        timer: 3000,
                        button: true
                    })
                }
                if (response.data && response.data.status == 0) {
                    swal({
                        title: "Failed!",
                        text: response.data.responseMessage,
                        icon: "info",
                        timer: 3000,
                        button: true
                    })
                }
            })
            .catch(error => {
                swal({
                    title: "Error!",
                    text: "unable to process your request",
                    icon: "error",
                    timer: 3000,
                    button: true
                })
            })
    }

    render() {
        const { studentRegistrationNumber, firstName, lastName, emailAddress, phoneNumber, otherNames, studentClassId, dateOfBirth } = this.state
        return (
            <>
                <AdminHeader />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">

                        </Col>
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Student information</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">

                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={this.editStudentHandler}>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">
                                                            First name
                            </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="firstName"
                                                            name="firstName"
                                                            type="text"
                                                            value={firstName}
                                                            onChange={this.changeHandler}
                                                            disabled={(this.state.disabled) ? "disabled" : ""}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">
                                                            Last name
                            </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="lastName"
                                                            name="lastName"
                                                            type="text"
                                                            value={lastName}
                                                            onChange={this.changeHandler}
                                                            disabled={(this.state.disabled) ? "disabled" : ""}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">
                                                            Other names
                            </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="otherNames"
                                                            name="otherNames"
                                                            type="text"
                                                            value={otherNames}
                                                            onChange={this.changeHandler}
                                                            disabled={(this.state.disabled) ? "disabled" : ""}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">
                                                            Email address
                            </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="emailAddress"
                                                            name="emailAddress"
                                                            type="text"
                                                            value={emailAddress}
                                                            onChange={this.changeHandler}
                                                            disabled={(this.state.disabled) ? "disabled" : ""}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">
                                                            Phone number
                            </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="phoneNumber"
                                                            name="phoneNumber"
                                                            type="text"
                                                            value={phoneNumber}
                                                            onChange={this.changeHandler}
                                                            disabled={(this.state.disabled) ? "disabled" : ""}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        {/* <label className="form-control-label">Date of birth</label>
                                                        <Input
                                                            className="form-control-alternative form-control"
                                                            id="dateOfBirth"
                                                            name="dateOfBirth"
                                                            placeholder="DoB"
                                                            type="date"
                                                            value={dateOfBirth}
                                                            onChange={this.changeHandler}
                                                            disabled={(this.state.disabled) ? "disabled" : ""}
                                                        /> */}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        <hr className="my-4" />
                                        {/* Address */}
                                        <h6 className="heading-small text-muted mb-4">
                                            Contact information
                    </h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup>
                                                        <label className="form-control-label">
                                                            Address
                            </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="Address"
                                                            name="Address"
                                                            type="text"
                                                            value=""
                                                            onChange={this.changeHandler}
                                                            disabled={(this.state.disabled) ? "disabled" : ""}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">
                                                            City
                            </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-city"
                                                            type="text"
                                                            disabled={(this.state.disabled) ? "disabled" : ""}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">
                                                            Country
                            </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-country"
                                                            type="text"
                                                            disabled={(this.state.disabled) ? "disabled" : ""}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">
                                                            Postal code
                            </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-postal-code"
                                                            type="number"
                                                            disabled={(this.state.disabled) ? "disabled" : ""}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        <hr className="my-4" />
                                        <Button
                                            type="submit"
                                            color="success"
                                            style={{ float: "right", visibility: this.state.visibility }}
                                        >
                                            Save
                      </Button>
                                        <Button
                                            type="button"
                                            color="success"
                                            style={{ float: "left" }}
                                            onClick={this.handleEditButton}
                                        >
                                            Edit
                      </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default StudentDetails